import SubscriptionPrice, { SubscriptionPriceType } from "types/SubscriptionPrice";

class SubscriptionTypeLocalizer {

  static getName(subscriptionTypeId: string) {
    if (subscriptionTypeId === '44a8449e-ce74-4611-b2c0-69f0c44c9bf2') {
      return 'Srebrn';
    }
  
    if (subscriptionTypeId === 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a') {
      return 'Zlat';
    }
  
    throw new Error(`Unable to get subscription type localized text: ${subscriptionTypeId}`);
  }
  
  static getCreateText(subscriptionTypeId: string) {
    if (subscriptionTypeId === '44a8449e-ce74-4611-b2c0-69f0c44c9bf2') {
      return 'Activate Silver subscription';
    }
  
    if (subscriptionTypeId === 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a') {
      return 'Activate Gold subscription …';
    }
  
    throw new Error(`Unable to get subscription type create localized text: ${subscriptionTypeId}`);
  }
  
  static getDescription(subscriptionTypeId: string) {
    if (subscriptionTypeId === '44a8449e-ce74-4611-b2c0-69f0c44c9bf2') {
      return 'Za vse, ki imate za sabo že nekaj poslovne kilometrine.';
    }
  
    if (subscriptionTypeId === 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a') {
      return 'Za vse, ki se v podjetništvu znajdete kot riba v vodi.';
    }
  
    throw new Error(`Unable to get subscription type description text: ${subscriptionTypeId}`);
  }
  
  static getInvoicesPerMonthText(subscriptionTypeId: string) {
    if (subscriptionTypeId === '44a8449e-ce74-4611-b2c0-69f0c44c9bf2') {
      return '30 računov na mesec';
    }
  
    if (subscriptionTypeId === 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a') {
      return 'Neomejeno';
    }
  
    throw new Error(`Unable to get subscription type invoices per month text: ${subscriptionTypeId}`);
  }
  
  static getInvoicesPerMonthTextForMobile(subscriptionTypeId: string) {
    if (subscriptionTypeId === '44a8449e-ce74-4611-b2c0-69f0c44c9bf2') {
      return '30 računov na mesec';
    }
  
    if (subscriptionTypeId === 'b9b412c9-ebe3-40a6-bbd2-187c3301c36a') {
      return 'Neomejeno računov na mesec';
    }
  
    throw new Error(`Unable to get subscription type invoices per month text: ${subscriptionTypeId}`);
  }
  
  static getPriceText(subscriptionPrice: SubscriptionPrice) {
    return subscriptionPrice.text + this.getPriceTextSuffix(subscriptionPrice.type);
  }
  
  private static getPriceTextSuffix(subscriptionPriceType: SubscriptionPriceType) {
    switch (subscriptionPriceType) {
      case SubscriptionPriceType.Monthly:
        return ' / mesec';
      case SubscriptionPriceType.Yearly:
        return ' / leto';
      default:
        throw new Error(`Unable to get subscription type price text suffix: ${subscriptionPriceType}`);
    }
  }

}

export default SubscriptionTypeLocalizer;
