import React from "react";
import Localizer from "utils/Localizer";
import InvoiceItemListSummary from "types/InvoiceItemListSummary";

type InvoiceItemListProps = {
  currency: string;
  summary: InvoiceItemListSummary;
}

const InvoiceSummary = (props: InvoiceItemListProps) => {
  
  const discountSummaryHtml = props.summary.discountPrice > 0 ? (
    <li>
      Popust skupaj:
      <span className="value">-{Localizer.getNumberString(props.summary.discountPrice, 2)} {props.currency}</span>
    </li>
  ) : null;

  const summaryClassName = props.summary.priceSum > 0 ? "summary r-float" : "summary r-float disabled";

  return (
    <ul className={summaryClassName}>
      {discountSummaryHtml}
      <li>
        Znesek brez DDV:
        <span className="value">{Localizer.getNumberString(props.summary.netoPriceSumWithDiscount, 2)} {props.currency}</span>
      </li>
      <li>
        DDV:
        <span className="value">{Localizer.getNumberString(props.summary.vatPrice, 2)} {props.currency}</span>
      </li>
      <li>
        Za plačilo:
        <span className="value">{Localizer.getNumberString(props.summary.priceSum, 2)} {props.currency}</span>
      </li>
    </ul>
  )

};

export default InvoiceSummary;
