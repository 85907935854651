import React from 'react';
import ExternalRoutes from 'constants/ExternalRoutes';

const LegalFooter = () => {
  return (
    <div className="legal-footer bottom-bar small">
      <a href={ExternalRoutes.privacy} rel="noopener noreferrer" target="_blank">Politika zasebnosti</a> | <a href={ExternalRoutes.terms} rel="noopener noreferrer" target="_blank">Pogoji uporabe</a>
    </div>
  );
};

export default LegalFooter;
