class PromiseHelper {
  
  static isFulfilled<T,>(p:PromiseSettledResult<T>): p is PromiseFulfilledResult<T> {
    return p.status === 'fulfilled';
  }

  static isRejected<T,>(p:PromiseSettledResult<T>): p is PromiseRejectedResult {
    return p.status === 'rejected';
  }

}

export default PromiseHelper;
