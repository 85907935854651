const getText = (invoiceTypeId: string) => {
  if (invoiceTypeId === '0') {
    return 'Račun';
  }

  if (invoiceTypeId === '1') {
    return 'Predračun';
  }

  if (invoiceTypeId === '2') {
    return 'Avansni račun';
  }

  throw new Error(`Unable to get invoice type localized text: ${invoiceTypeId}`);
};

const getInvoiceNumberLabelText = (invoiceTypeId: string) => {
  if (invoiceTypeId === '0') {
    return 'Št. računa';
  }
  
  if (invoiceTypeId === '1') {
    return 'Št. predračuna';
  }
  
  if (invoiceTypeId === '2') {
    return 'Št. avansnega računa';
  }

  throw new Error(`Unable to get invoice type localized text: ${invoiceTypeId}`);
};

const getCreateText = (invoiceTypeId: string) => {
  if (invoiceTypeId === '0') {
    return 'Ustvari nov račun';
  }

  if (invoiceTypeId === '1') {
    return 'Nov predračun …';
  }

  if (invoiceTypeId === '2') {
    return 'Nov avansni račun …';
  }

  throw new Error(`Unable to get invoice type create localized text: ${invoiceTypeId}`);
};

const getNextInvoiceNumberText = (invoiceTypeId: string) => {
  if (invoiceTypeId === '0') {
    return 'Št. naslednjega računa:';
  }

  if (invoiceTypeId === '1') {
    return 'Št. naslednjega predračuna:';
  }

  if (invoiceTypeId === '2') {
    return 'Št. naslednjega avansnega računa:';
  }

  throw new Error(`Unable to get next invoice number text: ${invoiceTypeId}`);
};

const getDefaultInvoiceNumberPrefixText = (invoiceTypeId: string) => {
  if (invoiceTypeId === '0') {
    return '';
  }

  if (invoiceTypeId === '1') {
    return 'PR ';
  }

  if (invoiceTypeId === '2') {
    return 'AR ';
  }

  throw new Error(`Unable to get default invoice number prefix text: ${invoiceTypeId}`);
}

export default { getText, getInvoiceNumberLabelText, getCreateText, getNextInvoiceNumberText, getDefaultInvoiceNumberPrefixText };
