import LastInvoicesPerYear from "../types/LastInvoicesPerYear";

class LastInvoiceSetter {
  
  private existingLastInvoices: LastInvoicesPerYear[];

  constructor(existingLastInvoices: LastInvoicesPerYear[] | undefined) {
    this.existingLastInvoices = existingLastInvoices ? existingLastInvoices : [];
  }
  
  setLastInvoice(invoiceTypeId: string, year: number, invoiceNumber: number, invoiceId: string) {
    let lastInvoicesPerYear = this.getExistingLastInvoicePerYear(year);
    if (!lastInvoicesPerYear) {
      lastInvoicesPerYear = {
        year: year,
        lastInvoices: []
      };

      this.existingLastInvoices.push(lastInvoicesPerYear);
    }

    let lastInvoice = this.getExistingLastInvoicePerType(lastInvoicesPerYear, invoiceTypeId);
    if (!lastInvoice) {
      lastInvoice = {
        id: invoiceId,
        invoiceNumber: invoiceNumber,
        year: year,
        invoiceTypeId: invoiceTypeId
      };

      lastInvoicesPerYear.lastInvoices.push(lastInvoice);
    }
    
    lastInvoice.invoiceNumber = invoiceNumber;
    lastInvoice.id = invoiceId;
    
    return this.existingLastInvoices;
  }

  private getExistingLastInvoicePerYear(year: number) {
    const list = this.existingLastInvoices.filter(x => x.year === year);
    if (list.length === 0) {
      return undefined;
    }

    return list[0];
  }

  private getExistingLastInvoicePerType(existingLastInvoicePerYear: LastInvoicesPerYear, invoiceTypeId: string) {
    const list = existingLastInvoicePerYear.lastInvoices.filter(x => x.invoiceTypeId === invoiceTypeId);
    if (list.length === 0) {
      return undefined;
    }

    return list[0];
  }

}

export default LastInvoiceSetter;
