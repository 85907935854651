class CurrencyProvider {

  static getValidCurrency(currency: string): string {
    const allCurrencies = this.getAllCurrencies();

    // If currency isn't defined, return first one. This fixes only tests currently.
    if (!currency) {
      return allCurrencies[0].value;
    }

    const found = allCurrencies.filter(x => x.value === currency.trim());
    if (found.length > 0) {
      return currency;
    }
    
    // Upgrade scenario: when user had some other currency selected which is not in the dropdown, return the first one.
    return allCurrencies[0].value;
  }

  static getAllCurrencies() {    
    return [
      { label: "Evro (EUR)", value: "EUR" },
      { label: "Ameriški dolar (USD)", value: "USD" },
      { label: "Japonski jen (JPY)", value: "JPY" },
      { label: "Britanski funt (GBP)", value: "GBP" },
      { label: "Avstralski dolar (AUD)", value: "AUD" },
      { label: "Kanadski dolar (CAD)", value: "CAD" },
      { label: "Švicarski frank (CHF)", value: "CHF" },
      { label: "Kitajski juan (CNY)", value: "CNY" },
      { label: "Švedska krona (SEK)", value: "SEK" },
      { label: "Novozelandski dolar (NZD)", value: "NZD" },
      { label: "Norveška krona (NOK)", value: "NOK" },
      { label: "Danska krona (DKK)", value: "DKK" },
      { label: "Singapurski dolar (SGD)", value: "SGD" },
      { label: "Hongkonški dolar (HKD)", value: "HKD" },
      { label: "Poljski zlot (PLN)", value: "PLN" },
      { label: "Madžarski forint (HUF)", value: "HUF" },
      { label: "Češka krona (CZK)", value: "CZK" },
      { label: "Južnokorejski von (KRW)", value: "KRW" },
      { label: "Turška lira (TRY)", value: "TRY" },
      { label: "Mehiški peso (MXN)", value: "MXN" },
      { label: "Brazilski real (BRL)", value: "BRL" },
      { label: "Ruski rubelj (RUB)", value: "RUB" },
      { label: "Južnoafriški rand (ZAR)", value: "ZAR" },
      { label: "Indijska rupija (INR)", value: "INR" },
      { label: "Indonezijska rupija (IDR)", value: "IDR" },
      { label: "Malezijski ringit (MYR)", value: "MYR" },
      { label: "Tajski baht (THB)", value: "THB" },
      { label: "Filipinski peso (PHP)", value: "PHP" },
      { label: "Vietnamski dong (VND)", value: "VND" },
      { label: "Dirham Združenih arabskih emiratov (AED)", value: "AED" },
      { label: "Savdska rial (SAR)", value: "SAR" },
      { label: "Izraelski novi šekel (ILS)", value: "ILS" },
    ];
  }

}

export default CurrencyProvider;
