import React, { useEffect, useState, createContext, useContext } from 'react';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import Bugsnag from '@bugsnag/js';
import { useFirebase } from 'config/Firebase';
import FullPageSpinner from 'components/FullPageSpinner';
import UserData from 'types/UserData';
import UserProvider from 'providers/UserProvider';
import OrganizationDataProvider from 'providers/OrganizationDataProvider';
import OrganizationData from 'types/OrganizationData';
import useBearerToken from './BearerToken';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const AuthContext = createContext<AuthContextInterface | null>(null);

type AuthProviderProps = {

  children: React.ReactNode;

}

interface AuthContextInterface {
  
  // Data about user which comes from firebase authentcation (uid, email)
  currentUser: firebase.default.User;

  // Data about user which comes from our managed database.
  userData: UserData;

  // A value indicating whether user has selected organization or not.
  // Not selected organization means that he is still in registration process.
  hasOrganizationSelected: boolean;

  setUserData: (newUserData: UserData) => void;

  organizationData: OrganizationData;

  setOrganizationData: (newOrganizationData: OrganizationData) => void;

  createBackendRequest: () => AxiosInstance;

}

export const AuthProvider = (props: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<firebase.default.User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const [hasData, setHasData] = useState<boolean>(false);
  const [listeningToAuthStateChanged, setListeningToAuthStateChanged] = useState(false);
  const bearerToken = useBearerToken();
  const firebaseContext = useFirebase();
  const db = firebaseContext!.firebase.db();

  useEffect(() => {
    const organizationSelectUrl = Router.route(Routes.selectOrganization);
    const homeUrl = Router.route(Routes.home);
    const loginUrl = Router.route(Routes.login);
    const noConnectionUrl = Router.route(Routes.error, { errorId: 'no-connection' });
    const errorUrl = Router.route(Routes.error, { errorId: 'generic' });

    const isOnPage = (url: string) => {
      return window.location.pathname === url;
    };
  
    const redirectTo = (url: string) => {
      if (!isOnPage(url)) {
        window.location.href = url;
        console.log('Redirecting to: ' + url);
      }
    }
  
    const redirectToSelectOrganization = () => {
      redirectTo(organizationSelectUrl);
    };
  
    const redirectToNoInternetConnectionPage = () => {
      redirectTo(noConnectionUrl);
    };

    const redirectToHome = () => {
      redirectTo(homeUrl);
    };

    const onAuthenticationStateChanged = async (user: firebase.default.User | null) => {
      console.log(`onAuthenticationStateChanged`);
      console.log(user);
      
      if (user && !userData) {
        setCurrentUser(user);
        setHasData(false);

        // No need to request user data on some pages ...
        if (isOnPage(noConnectionUrl) || isOnPage(organizationSelectUrl) || isOnPage(errorUrl)) {
          setHasData(true);
          return;
        }

        // If user is on login page while already logged in, redirect it to home
        if (isOnPage(loginUrl)) {
          redirectToHome();
          return;
        }
        
        try {
          const userProvider = new UserProvider(db);
          const userData = await userProvider.getUserData(user.uid);
          if (!userData) {
            console.log('Unable to get user data, probably a new user ...');
            redirectToSelectOrganization();
            return;
          }

          // If user doesn't have organization yet, redirect him to the page where he can select it.
          if (!userData.organizationId) {
            redirectToSelectOrganization();
            return;
          }
          
          setUserData(userData);
          
          const organizationProvider = new OrganizationDataProvider(db);
          const organizationData = await organizationProvider.getOrganizationData(userData.organizationId);
          if (!organizationData) {
            throw new Error(`Unable to get organization data for user: ${user.uid} with organization: ${userData.organizationId}`);
          }

          setOrganizationData(organizationData);
          setHasData(true);

          Bugsnag.setUser(user.uid, user.email ? user.email : undefined, userData.organizationName);
        }
        catch (error) {
          if (error instanceof Error) {
            Bugsnag.notify(error);
          }

          const errorCode = (error as any).code;
          console.log(error);

          switch (errorCode) {
            case 'unavailable':
              console.log('No internet connection, redirecting ...');
              redirectToNoInternetConnectionPage();
              return;
            default:
              redirectTo(errorUrl);
              break;
          }
        }
      } else {
        setUserData(null);
        setOrganizationData(null);
        setCurrentUser(null);
        setHasData(true);
      }
    };

    if (!listeningToAuthStateChanged) {
      firebaseContext!.firebase.onAuthStateChanged(onAuthenticationStateChanged);
      
      setListeningToAuthStateChanged(true);
    }
  }, [ firebaseContext, db, currentUser, userData, listeningToAuthStateChanged ]);

  if (!hasData) {
    return (
      <FullPageSpinner text="Pridobivam podatke o uporabniku …" />
    );
  }

  // This can happen during logout.
  if (currentUser && !bearerToken) {
    return (
      <FullPageSpinner />
    );
  }

  const hasOrganizationSelected = () => {
    if (!userData) {
      return false;
    }

    return userData.organizationId ? true : false;
  };
  
  const createBackendRequest = () => {
    const config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_BACKEND_URL
    };

    if (bearerToken) {
      config.headers = {
        'Authorization': `Bearer ${bearerToken}`
      }
    }

    return Axios.create(config);
  }

  return (
    <AuthContext.Provider value={{ currentUser: currentUser!, hasOrganizationSelected: hasOrganizationSelected(), userData: userData!, setUserData, organizationData: organizationData!, setOrganizationData, createBackendRequest }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const nullableContext = useContext(AuthContext);
  if (nullableContext) {
    return nullableContext;
  }
  
  throw new Error('Auth context null!');
}
