import React from 'react';
import * as Routes from 'constants/Routes.js';
import * as ControlPanelRoutes from 'constants/ControlPanelRoutes';
import Empty from 'components/Empty.js';
import Documents from 'components/pages/Documents';
import Document from 'components/pages/Document.js';
import Partners from 'components/pages/Partners';
import Partner from 'components/pages/Partner.js';
// import Organizations from 'components/pages/Organizations.js';
import Organization from 'components/pages/Organization';
import OrganizationSubscription from 'components/pages/OrganizationSubscription';
import SelectOrganization from 'components/pages/SelectOrganization.js';
import Settings from 'components/pages/Settings.js';
import Login from 'components/pages/Login.js';
import ResetPassword from 'components/pages/ResetPassword.js';
import SignUp from 'components/pages/SignUp.js';
import NotFound from 'components/pages/NotFound.js';
import ControlPanel from 'components/pages/cp/ControlPanel';
import ControlPanelOrganizations from 'components/pages/cp/Organizations';
import ControlPanelOrganization from 'components/pages/cp/Organization';
import ControlPanelOrganizationUpgradeFunctions from 'components/pages/cp/OrganizationUpgradeFunctions';
import ControlPanelOrganizationSubscriptions from 'components/pages/cp/OrganizationSubscriptions';
import Error from 'components/pages/Error.js';
import Navigation from 'components/Navigation.js';
import Router from 'utils/Router.js';

let cachedPages;

export const GetAll = () => {
  if (!cachedPages) {
    cachedPages = [
      Page(Router.route(Routes.login), Login),
      Page(Router.route(Routes.resetPassword), ResetPassword),
      Page(Router.route(Routes.signup), SignUp),
      Page(Router.route(Routes.selectOrganization), SelectOrganization, true, true),
      PageWithNavigation(Router.route(Routes.home), Documents, "Računi"),
      PageWithNavigation(Router.route(Routes.documents, { documentId: ':documentId' }), Document, "Računi"),
      PageWithNavigation(Router.route(Routes.partners), Partners, "Partnerji"),
      PageWithNavigation(Router.route(Routes.partners, { partnerId: ':partnerId' }), Partner, "Partnerji"),
      PageWithNavigation(Router.route(Routes.settings), Settings, "Nastavitve"),
      //PageWithNavigation(Router.route(Routes.organizations), Organizations, "Nastavitve"),
      PageWithNavigation(Router.route(Routes.organizations, { organizationId: ':organizationId' }), Organization, "Nastavitve"),
      PageWithNavigation(Router.route(Routes.organizationSubscriptions, { organizationId: ':organizationId' }), OrganizationSubscription, "Paketi"),
      PageWithNavigation(Router.route(ControlPanelRoutes.controlPanel), ControlPanel, "Nadzorna plošča"),
      PageWithNavigation(Router.route(ControlPanelRoutes.organizations), ControlPanelOrganizations, "Organizacije"),
      PageWithNavigation(Router.route(ControlPanelRoutes.organizations, { organizationId: ':organizationId' }), ControlPanelOrganization, "Organizacija"),
      PageWithNavigation(Router.route(ControlPanelRoutes.organizationUpgradeFunctions, { organizationId: ':organizationId' }), ControlPanelOrganizationUpgradeFunctions, "Organizacija"),
      PageWithNavigation(Router.route(ControlPanelRoutes.organizationSubscriptions, { organizationId: ':organizationId' }), ControlPanelOrganizationSubscriptions, "Organizacija"),
      Page(Router.route(Routes.error, { errorId: ':errorId' }), Error),
      Page('*', NotFound, false)
    ]
  }

  return cachedPages;
};

const PageWithNavigation = (path, component, selection) => {
  const navigation = () => {
    return (
      <Navigation selection={selection} />
    );
  };

  return {
    path: path,
    private: true,
    exact: true,
    sidebar: navigation,
    component: component
  };
};

const Page = (path, component, exact, isPrivate) => {
  if (!exact) {
    exact = true;
  }

  return {
    path: path,
    private: isPrivate ? isPrivate : false,
    exact: exact,
    sidebar: Empty,
    component: component
  };
};
