import React from "react";
import Localizer from "utils/Localizer";
import NumberFormat from "react-number-format";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InvoiceItemModel from "types/InvoiceItemModel";

type InvoiceItemProps = {
  currency: string;
  model: InvoiceItemModel;
  removeButtonVisible: boolean;
  onRemoveButtonClicked: (model: InvoiceItemModel) => void;
  onNameChanged: (model: InvoiceItemModel, newName: string) => void;
  onUnitChanged: (model: InvoiceItemModel, newUnit: string) => void;
  onQuantityChanged: (model: InvoiceItemModel, value: number) => void;
  onPriceChanged: (model: InvoiceItemModel, value: number) => void;
  onVatPercentageChanged: (model: InvoiceItemModel, value: number) => void;
  onDiscountPercentageChanged: (model: InvoiceItemModel, value: number) => void;
}

const InvoiceItemLine = (props: InvoiceItemProps) => {
  
  const getInputClassName = (model: InvoiceItemModel) => {
    let className = 'inputish inputable';

    if (!model.isNew) {
      className += ' touched';
    }

    return className;
  };

  const handleRemoveButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();

    props.onRemoveButtonClicked(props.model);
  };
  
  const handleNameChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onNameChanged(props.model, event.target.value);
  };

  const handleQuantityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyValue = parseFloat(event);
    props.onQuantityChanged(props.model, propertyValue);
  };

  const handleUnitChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUnitChanged(props.model, event.target.value);
  };

  const handlePriceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyValue = parseFloat(event);
    props.onPriceChanged(props.model, propertyValue);
  };

  const handleVatPercentageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyValue = parseFloat(event);
    props.onVatPercentageChanged(props.model, propertyValue);
  };

  const handleDiscountPercentageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const propertyValue = parseFloat(event);
    props.onDiscountPercentageChanged(props.model, propertyValue);
  };

  const parseFloat = (event: React.ChangeEvent<HTMLInputElement>) => {
    return event.target.value.length > 0 ? Localizer.parseFloat(event.target.value) : 0;
  };
  
  const removeButton = (model: InvoiceItemModel) => {
    if (!props.removeButtonVisible) {
      return null;
    }

    return (<div className="floaty remove-button" data-id={model.item.id} onClick={handleRemoveButtonClick}>
                &#10005;
            </div>);
  };

  const selectInputText = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.select();
    event.target.classList.add('touched');
  };

  const setDefaultValueIfEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = event.target.value.trim().length === 0;
    if (isEmpty) {
      const defaultValue = event.target.getAttribute('data-default');
      if (defaultValue) {
        event.target.value = defaultValue;
      }
    }
  };

  const isValidPercentage = () => {
    return true;
  };

  return (
    <div className="invoice-item row" key={props.model.item.id}>
      {removeButton(props.model)}
      <div className="line-label show-only-on-mobile">
        Opis storitve
      </div>
      <div className="col fluid">
        <TextareaAutosize
          className={getInputClassName(props.model)}
          autoFocus={props.model.shouldAutoFocus}
          value={props.model.item.name}
          onFocus={selectInputText}
          onChange={handleNameChanged}
          data-id={props.model.item.id} />
      </div>
      <div className="line-label show-only-on-mobile">
        Količina
      </div>
      <div className="col fixed shortest">
        <NumberFormat
          className={getInputClassName(props.model)}
          value={props.model.item.quantity}
          decimalScale={2}
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          onFocus={selectInputText}
          onBlur={setDefaultValueIfEmpty}
          data-default={props.model.quantityDefault}
          onChange={handleQuantityChanged}
          data-id={props.model.item.id}
          isAllowed={isValidPercentage} />
      </div>
      <div className="col fixed shortest">
        <input
          className={getInputClassName(props.model)}
          type="text"
          value={props.model.item.unit}
          onChange={handleUnitChanged}
          onFocus={selectInputText} />
      </div>
      <div className="space-between show-only-on-mobile">
        <div className="line-label">
          Cena
        </div>
        <div className="line-label short">
          Davek
        </div>
        <div className="line-label short">
          Popust
        </div>
      </div>
      <div className="col fixed w-appendix groupable">
        <NumberFormat
          className={getInputClassName(props.model)}
          value={props.model.item.price}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          onFocus={selectInputText}
          onBlur={setDefaultValueIfEmpty}
          data-default={props.model.priceDefault}
          onChange={handlePriceChanged}
          data-id={props.model.item.id} />
        <div className="appendix-unit">{props.currency}</div>
      </div>
      <div className="col fixed short w-appendix groupable">
        <NumberFormat
          className={getInputClassName(props.model)}
          value={props.model.item.vatPercentage}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          onFocus={selectInputText}
          onBlur={setDefaultValueIfEmpty}
          data-default={props.model.vatPercentageDefault}
          onChange={handleVatPercentageChanged}
          data-id={props.model.item.id}
          isAllowed={isValidPercentage} />
        <div className="appendix-unit">%</div>
      </div>
      <div className="col fixed short w-appendix groupable">
        <NumberFormat
          className={getInputClassName(props.model)}
          value={props.model.item.discountPercentage}
          decimalScale={2}
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          onFocus={selectInputText}
          onBlur={setDefaultValueIfEmpty}
          data-default={props.model.discountPercentageDefault}
          onChange={handleDiscountPercentageChanged}
          data-id={props.model.item.id}
          isAllowed={isValidPercentage} />
        <div className="appendix-unit">%</div>
      </div>
      <div className="col fixed w-appendix hide-on-mobile">
        <NumberFormat
          className="inputish inputable"
          value={props.model.summary.netoPriceSum - props.model.summary.discountPrice}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator={Localizer.getThousandsSeparator()}
          decimalSeparator={Localizer.getDecimalSeparator()}
          disabled />
        <div className="appendix-unit">{props.currency}</div>
      </div>
      <div className="show-only-on-mobile clear-float"></div>
    </div>
  );

};

export default InvoiceItemLine;
