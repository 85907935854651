import React from "react";

const InvoiceItemHeader = () => {

  return (
    <div className="invoice-item header">
      <div className="col fluid">
        <div className="line-label">
          Opis storitve
        </div>
      </div>
      <div className="col fixed shortest">
        <div className="line-label">
          Količina
        </div>
      </div>
      <div className="col fixed shortest">
        <div className="line-label">
          Enota
        </div>
      </div>
      <div className="col fixed w-appendix">
        <div className="line-label">
          Cena
        </div>
      </div>
      <div className="col fixed short w-appendix">
        <div className="line-label">
          Davek
        </div>
      </div>
      <div className="col fixed short w-appendix">
        <div className="line-label">
          Popust
        </div>
      </div>
      <div className="col fixed w-appendix">
        <div className="line-label">
          Skupaj brez DDV
        </div>
      </div>
    </div>
  );

};

export default InvoiceItemHeader;
