import React from 'react';

const Attribution = ({ onChange, ...other}) => {

  const handleChange = (event) => {
    if (!onChange) {
      return;
    }
    
    const value = event.target.value;
    onChange(value);
  };

  return (
    <textarea className="inputish" onChange={handleChange} {...other} />
  );

};

export default Attribution;
