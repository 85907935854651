import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useAuth } from 'utils/Auth';
import { useFirebase } from 'config/Firebase';

const LiveSelectBox = ({ collectionName, field, name, searchable, defaultValue, defaultText, register, onChange }) => {

  const defaultOption = {
    value: defaultValue ? defaultValue : '',
    label: defaultText ? defaultText : ''
  };

  const [selectedValue, setSelectedValue] = useState(defaultOption.value);
  const [selectedText, setSelectedText] = useState(defaultOption.label);
  const [allOptions, setAllOptions] = useState(null);
  const [searching, setSearching] = useState(false);
  
  const currentUser = useAuth().currentUser;
  const firebaseContext = useFirebase();

  const filterOptions = (options, textFilter) => {
    if (textFilter.length === 0) {
      return options;
    }

    const searchTerms = textFilter.toLowerCase().split(' ');
    return options.filter(function(option) {
      return searchTerms.every(function(el) {
        return option.label.toLowerCase().indexOf(el) > -1;
      });
    });
  };

  const mapToOptions = (snapshot) => {
    let options = [];
    snapshot.forEach(document => {
      const data = document.data();
      const name = data[field];
      const option = {
        value: document.id,
        label: name
      };
      options.push(option);
    });
    return options;
  };
  
  const loadOptions = (inputValue, callback) => {
    if (searching) {
      return;
    }

    if (allOptions) {
      const filteredOptions = filterOptions(allOptions, inputValue);
      callback(filteredOptions);
    }
    else {
      const db = firebaseContext.firebase.db();
      const query = db.collection(collectionName).where('authorUid', '==', currentUser.uid);

      query.get()
        .then(snapshot => {
          const options = mapToOptions(snapshot);
          setAllOptions(options);
          setSearching(false);

          const filteredOptions = filterOptions(options, inputValue);
          callback(filteredOptions);
        })
        .catch(error => {
          console.log(`Error while retrieving select box options: ${error}`);
        });

      setSearching(true);
    }
  };
  
  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    setSelectedText(selectedOption.label);
    
    if (onChange) {
      onChange(selectedOption);
    }
  };
  
  return (
    <>
      <AsyncSelect
      cacheOptions
      defaultOptions={searchable ? false : true}
      isSearchable={searchable ? true : false}
      loadOptions={loadOptions}
      onChange={handleChange}
      defaultValue={defaultOption}
      name={name}
      placeholder="Izberi ..." />
      <input type="hidden" name={name + 'Id'} defaultValue={selectedValue} ref={register} />
      <input type="hidden" name={name + 'Name'} defaultValue={selectedText} ref={register} />
    </>
  );
};

export default LiveSelectBox;
