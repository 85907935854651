import React from 'react';
import { pdf } from '@react-pdf/renderer';
import InvoicePdf from 'components/documents/invoice/InvoicePdf.js';
import Base64Provider from 'utils/Base64Provider';
import SubscriptionsProvider from "providers/SubscriptionsProvider";
var FileSaver = require('file-saver');

const downloadPdf = async (invoice, fileName, pdfSettings) => {
  const blob = await generatePdf(invoice, pdfSettings);
  FileSaver.saveAs(blob, fileName);
};

const getPdfBase64 = async (invoice, pdfSettings) => {
  const blob = await generatePdf(invoice, pdfSettings);
  return Base64Provider.getBase64String(blob);
};

const printPdf = async (invoice, pdfSettings) => {  
  // Create PDF and get URL pointing to it
  const blob = await generatePdf(invoice, pdfSettings);
  const fileURL = window.URL.createObjectURL(blob);
  
  // Create invisible iframe, append it to the body
  const container = document.getElementById('invisible-print-container');
  const iframe = document.createElement('iframe');
  container.appendChild(iframe);
  iframe.src = fileURL;

  // Print it (this shit doesn't work without the timeout - tested on Chrome - looks like it need to process the iframe creation or sth)
  setTimeout(function() {
    iframe.contentWindow.print();
  }, 100);
};

const generatePdf = async (invoice, pdfSettings) => {
  
  const provider = new SubscriptionsProvider(invoice.db, invoice.metadata.organizationId);
  const activeSubscriptions = await provider.getActiveSubscriptions();
  const logoWatermarkVisible = activeSubscriptions.length === 0;

  return await pdf((
    <InvoicePdf invoice={invoice} logoWatermarkVisible={logoWatermarkVisible} pdfSettings={pdfSettings} />
  )).toBlob();

};

export default { downloadPdf, generatePdf, getPdfBase64, printPdf };
