import React from 'react';

const TextBox = ({ autoFocus, className, label, height, validationFailed, fullLine, multiLine, info, visible, ...other}, ref) => {
  if (visible === undefined) {
    visible = true;
  }

  const getClassName = () => {
    let name = "text-box control-row";

    if (!visible) {
      name += " hidden";
    }

    if (fullLine) {
      name += " full-line";
    }

    if (className) {
      name += " " + className;
    }

    return name;
  };

  const getStyle = () => {
    let style = {};

    if (height) {
      style.height = height;
      style.minHeight = height;
    }

    return style;
  };

  const inputHtml = multiLine ? (
    <textarea style={getStyle()} autoFocus={autoFocus} className={validationFailed ? 'inputish validation-failed' : 'inputish'} ref={ref} {...other} />
  ) : (
    <input style={getStyle()} autoFocus={autoFocus} className={validationFailed ? 'inputish validation-failed' : 'inputish'} ref={ref} {...other} />
  );

  return (
    <label className={getClassName()}>
      <span className="text">{label}</span>
      <div className="input-holder">
        <div className="flex-wrapper">
          {inputHtml}
          {info}
        </div>
      </div>
    </label>
  );
};

export default React.forwardRef(TextBox);
