import React from 'react';

type ControlPanelListTitle = {

  children: any;

}

const ControlPanelListTitle = (props: ControlPanelListTitle) => {
 
  return (
    <ol className="row header">
      <li className="col main">
        <span className="text-list-item noselect clickable selected direction0">
          {props.children}
        </span>
      </li>
    </ol>
  );

};

export default ControlPanelListTitle;
