import SecondaryButton from "components/SecondaryButton";
import { useFirebase } from "config/Firebase";
import React from "react";

type DownloadEinvoiceButtonProps = {
  disabled: boolean;
  onClick: (extension: 'pdf' | 'zip' | 'xml') => {};
};

const DownloadEinvoiceButton = (props: DownloadEinvoiceButtonProps) => {

  const firebaseContext = useFirebase();

  const handleZipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    firebaseContext!.firebase.logEvent('download_zip');
    
    props.onClick('zip');
  };

  const handleXmlClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    firebaseContext!.firebase.logEvent('download_xml');
    
    props.onClick('xml');
  };

  const actions = [
    {
      onClick: handleZipClick,
      text: 'Prenesi z ovojnico (.zip)'
    },
    {
      onClick: handleXmlClick,
      text: 'Prenesi kot XML'
    }
  ];

  return (
    <>
      <button className="buttonish text-only secondary w-secondary" onClick={handleZipClick} disabled={props.disabled}>Prenesi kot e-račun</button>
      <SecondaryButton className="secondary" disabled={props.disabled} actions={actions} anchorAtBottom={true} />
    </>
  )

};

export default DownloadEinvoiceButton;
