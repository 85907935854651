import React, { Dispatch, KeyboardEventHandler, SetStateAction, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import getSelectStyle from 'styles/SelectStyle.js';

type CreatableBox = {
  label?: string | undefined;
  placeholder?: string | undefined;
  autoFocus?: boolean | undefined;
  validationFailed?: boolean | undefined;
  className?: string | undefined;
  fullLine?: boolean | undefined;
  visible?: boolean | undefined;
  value: readonly CreatableOption[];
  setValue: Dispatch<SetStateAction<CreatableOption[]>>;
}

export interface CreatableOption {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const CreatableBox = (props: CreatableBox) => {
  const visible = props.visible !== undefined ? props.visible : true;
  const fullLine = props.fullLine !== undefined ? props.fullLine : true;
  const placeholder = props.placeholder !== undefined ? props.placeholder : '';

  const getClassName = () => {
    let name = "select-box control-row";

    if (!visible) {
      name += " hidden";
    }

    if (fullLine) {
      name += " full-line";
    }

    if (props.className) {
      name += " " + props.className;
    }

    return name;
  };

  const selectStyle = getSelectStyle(props.validationFailed);
  const defaultStyle = {
    ...selectStyle,
    container: (provided: any) => ({
      ...provided,
      width: !fullLine ? '50%' : provided.width,
      display: !fullLine ? 'inline-block' : provided.display
    })
  };
  
  const [inputValue, setInputValue] = useState('');
  
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
        tryCreateNewValue();
        event.preventDefault();
    }
  };

  const handleBlur = () => {
    tryCreateNewValue();
  };

  const tryCreateNewValue = () => {
    if (inputValue.trim() === '') {
      return;
    }

    props.setValue((prev: CreatableOption[]) => [...prev, createOption(inputValue)]);
    setInputValue('');
  };

  return (
    <label className={getClassName()}>
      {props.label ? (<span className="text">{props.label}</span>) : undefined}
      <CreatableSelect
      className="select-box-control"
      onBlur={handleBlur}
      autoFocus={props.autoFocus}
      placeholder={placeholder}
      inputValue={inputValue}
      isClearable={false}
      isMulti
      menuIsOpen={false}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      styles={defaultStyle}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      value={props.value}
      onChange={(newValue) => props.setValue(newValue.flat())} />
    </label>
  );
};

export default CreatableBox;
