import InvoiceTypeProvider from '../utils/InvoiceTypeProvider';
import OrganizationData from "../types/OrganizationData";
import LastInvoiceData from '../types/LastInvoiceData';
import InvoiceTypeLocalizer from '../utils/InvoiceTypeLocalizer';
import InvoiceNumberPrefixText from '../types/InvoiceNumberPrefixText';
import CurrencyProvider from '../utils/CurrencyProvider';
import firebase from 'firebase/compat/app';

class OrganizationProvider {

  get(rawData: firebase.firestore.DocumentData) {
    const organizationData = rawData as OrganizationData;
    
    if (!rawData.lastInvoices) {
      if (rawData.lastInvoice) {
        // Backwards compatibility. Back in time we didn't have support for proforma and prepayment invoice so the information
        // about last invoice number was stored as lastInvoice property on the organization document in the firestore.
        organizationData.lastInvoices = this.getLastInvoices(rawData.lastInvoice);
      } else {
        // Support for new user registered on 1.4 version or later. After registration he will not have lastInvoices property set yet,
        // so we set it to empty array here.
        organizationData.lastInvoices = [];
      }
    }

    // If invoiceNumberPrefixTexts is not set on the organization data, create default prefixes.
    if (!rawData.invoiceNumberPrefixTexts) {
      organizationData.invoiceNumberPrefixTexts = this.getDefaultInvoiceNumberPrefixTexts();
    }

    if (!rawData.cultureCode) {
      organizationData.cultureCode = 'sl';
    }

    if (organizationData.visibleVatBreakdownOnPdf === undefined) {
      organizationData.visibleVatBreakdownOnPdf = false;
    }

    if (organizationData.visibleQrCodeOnPdf === undefined) {
      organizationData.visibleQrCodeOnPdf = false;
    }

    organizationData.currency = CurrencyProvider.getValidCurrency(organizationData.currency);

    return organizationData;
  }

  private getLastInvoices(lastInvoice: LastInvoiceData) {
    const defaultInvoiceTypeId = InvoiceTypeProvider.getDefault().id;
    const lastDefaultInvoice = {
      ...lastInvoice,
      invoiceTypeId: defaultInvoiceTypeId
    };
    const lastInvoicesPerYear = {
      year: lastInvoice.year,
      lastInvoices: [
        lastDefaultInvoice
      ]
    }

    return [
      lastInvoicesPerYear
    ];
  }

  private getDefaultInvoiceNumberPrefixTexts() {
    const invoiceNumberPrefixTexts: InvoiceNumberPrefixText[] = [];

    const invoiceTypes = InvoiceTypeProvider.getAll();
    invoiceTypes.forEach(invoiceType => {
      const invoiceNumberPrefixText: InvoiceNumberPrefixText = {
        invoiceTypeId: invoiceType.id,
        prefixText: InvoiceTypeLocalizer.getDefaultInvoiceNumberPrefixText(invoiceType.id)
      };
      invoiceNumberPrefixTexts.push(invoiceNumberPrefixText);
    });

    return invoiceNumberPrefixTexts;
  }

}

export default OrganizationProvider;
