import { useFirebase } from 'config/Firebase';
import React from 'react';
import InvoiceAttachmentFileUploader from 'utils/InvoiceAttachmentFileUploader';
import { v4 } from 'uuid';

type UploadAttachmentButtonProps = {
  organizationId: string;
  onUploadStarted: (fileId: string, fileName: string) => void;
  onUploadProgress: (fileId: string, progress: number) => void;
  onUploadSuccess: (fileId: string, url: string) => void;
  onUploadError: (fileId: string, message: string) => void;
};

const UploadAttachmentButton = (props: UploadAttachmentButtonProps) => {
  
	const firebaseContext = useFirebase();

  const getErrorMessage = (error: any) => {
    if (error instanceof Error) {
      return error.message;
    }

    return 'Napaka pri nalaganju datoteke.';
  };

	const onFileInputClicked = (event: React.MouseEvent<HTMLInputElement>) => {
    // This will clear the input value selection. This fixes the change event not being called if image was removed and then
    // selected one more time.
    // Ref.: https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
    (event.target as any).value = null;
  };

	const onFileInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files && event.target.files.length > 0 ? event.target.files : [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      const fileId = v4();
      props.onUploadStarted(fileId, file.name);

      uploadFile(fileId, file);
    }
	};

  const uploadFile = async (fileId: string, file: File) => {
    try {
      const fileUploader = new InvoiceAttachmentFileUploader(firebaseContext!.firebase, (file, progress) => props.onUploadProgress(fileId, progress));
      const url = await fileUploader.uploadFile(file, props.organizationId);
      props.onUploadSuccess(fileId, url);
    }
    catch (error) {
      const errorMessage = getErrorMessage(error);
      props.onUploadError(fileId, errorMessage);
    }
  }

  const fileInput = (
    <input type="file" onChange={onFileInputChanged} onClick={onFileInputClicked} multiple />
  );

  return (<>
    <label className="upload-attachment link action hide-on-mobile">
      Naloži …
      {fileInput}
    </label>
    <label className="buttonish secondary show-only-on-mobile">
      Naloži …
      {fileInput}
    </label>
  </>);

};

export default UploadAttachmentButton;
