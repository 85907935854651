import React from 'react';
import { Link } from 'react-router-dom';
import Router from 'utils/Router';
import { ReactComponent as DocumentIcon } from 'icons/bills.svg';
import * as Routes from 'constants/Routes.js';

type RelatedDocumentProps = {
  invoiceId: string;
  invoiceNumberText: string;
}

const RelatedDocument = (props: RelatedDocumentProps) => {

  return (
    <Link to={Router.route(Routes.documents, { documentId: props.invoiceId })} className="invoice-attachment">
      <DocumentIcon />
      {props.invoiceNumberText}
    </Link>
  );

}

export default RelatedDocument;
