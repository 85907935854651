import Package from 'components/forms/Package';
import PackageHeader from 'components/forms/PackageHeader';
import TabControl from 'components/forms/TabControl';
import { useFirebase } from 'config/Firebase';
import useActiveSubscriptionInfo from 'hooks/useActiveSubscriptionInfo';
import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { SubscriptionPriceType } from 'types/SubscriptionPrice';
import SubscriptionTypeLocalizer from 'utils/SubscriptionTypeLocalizer';
import SubscriptionTypeProvider from 'utils/SubscriptionTypeProvider';

type OrganizationSubscriptionParams = {
  organizationId: string;
};

const OrganizationSubscription = () => {
  
  const { organizationId } = useParams<OrganizationSubscriptionParams>();
  const query = new URLSearchParams(useLocation().search);
  const source = query.get('source');
  const [packagesTypeIndex, setPackagesTypeIndex] = useState<number | undefined>();
  const { activeSubscriptions } = useActiveSubscriptionInfo(organizationId);
  const firebaseContext = useFirebase();
  
  // Select yearly subscription tab by default if the yearly subscription is currently active, otherwise select monthly subscription tab.
  useEffect(() => {    
    if (!packagesTypeIndex && activeSubscriptions) {
      if (activeSubscriptions.length > 0) {
        const firstActiveSubscription = activeSubscriptions[0];
        if (firstActiveSubscription.stripePriceId) {
          const subscriptionType = SubscriptionTypeProvider.getPriceById(firstActiveSubscription.stripePriceId);
          if (subscriptionType.type === SubscriptionPriceType.Yearly) {
            setPackagesTypeIndex(1);
            return;
          }
        }
      }

      setPackagesTypeIndex(0);
    }
  }, [activeSubscriptions, packagesTypeIndex]);
  
  // Log view subscriptions event
  useEffect(() => {
    if (packagesTypeIndex !== undefined) {
      firebaseContext!.firebase.logEvent('view_subscriptions', { source: source, type: packagesTypeIndex });
    }
  }, [firebaseContext, source, packagesTypeIndex]);

  if (!activeSubscriptions || packagesTypeIndex === undefined) {
    return <></>
  }

  const subscriptionTypes = SubscriptionTypeProvider.getAll();
  const subscriptionPackages = subscriptionTypes.map(subscriptionType => {
    const price = subscriptionType.prices.filter(price => price.type === packagesTypeIndex)[0];
    const activeSubscriptionsForPrice = activeSubscriptions.filter(activeSubscription => activeSubscription.stripePriceId === price.id);
    const activeSubscription = activeSubscriptionsForPrice.length > 0 ? activeSubscriptionsForPrice[0] : undefined;
    return (
      <Package name={SubscriptionTypeLocalizer.getName(subscriptionType.id)}
      key={subscriptionType.id}
      description={SubscriptionTypeLocalizer.getDescription(subscriptionType.id)}
      invoicesPerMonthText={SubscriptionTypeLocalizer.getInvoicesPerMonthText(subscriptionType.id)}
      invoicesPerMonthTextForMobile={SubscriptionTypeLocalizer.getInvoicesPerMonthTextForMobile(subscriptionType.id)}
      isActive={activeSubscription ? true : false}
      customerId={activeSubscription ? activeSubscription.stripeCustomerId : undefined}
      canBuy={activeSubscriptions.length === 0}
      priceId={price.id}
      priceText={SubscriptionTypeLocalizer.getPriceText(price)}
      organizationId={organizationId}
      hasWatermark={subscriptionType.hasWatermark}
      hasPhoneSupport={subscriptionType.hasPhoneSupport}
      isMostPopular={subscriptionType.isMostPopular && activeSubscriptions.length === 0}
      />
    );
  });

  return (
    <div className="page">
      <div className="dummy-top hide-on-mobile"></div>
      <h1 className="hide-on-mobile">Paketi</h1>
        <div className="panel">
          <div className="package-types-tabs">
            <TabControl selectedIndex={packagesTypeIndex} options={["Mesečni", "Letni"]} onSelectedIndexChanged={setPackagesTypeIndex} />
          </div>
          <div className="pricing-flex flex">
            <PackageHeader />
            <div className="column inline-flex">
              <Package name="Zelen" description="Za vse, ki šele začenjate svojo poslovno pot." invoicesPerMonthText="2 računa na mesec" invoicesPerMonthTextForMobile="2 računa na mesec" priceText="0 €" isActive={activeSubscriptions.length === 0} canBuy={false} organizationId={organizationId} hasWatermark={true} hasPhoneSupport={false} isMostPopular={false} />
              {subscriptionPackages}
            </div>
          </div>
          <div className="pricing-footer">
            Letno naročnino na izbrani paket lahko skleneš tudi preko bančnega nakazila.
            <div>Za predračun nam piši na <a href="mailto:info@evelope.si">info@evelope.si</a>.</div>
          </div>
        </div>
    </div>
  );
};

export default withRouter(OrganizationSubscription);
