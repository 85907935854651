import { useFirebase } from 'config/Firebase';
import { useAuth } from 'utils/Auth';
import CollectionHelper from 'utils/CollectionHelper';

const useCollection = (collectionName) => {
  
  const auth = useAuth();
  let currentUser = auth ? auth.currentUser : null;
  const firebaseContext = useFirebase();
  
  const getAll = async () => {
    console.log(`useCollection - getAll documents from collection: ${collectionName}`);

    const db = firebaseContext.firebase.db();
    const query = db.collection(collectionName).where('authorUid', '==', currentUser.uid);
    return query.get();
  };

  const get = async (documentId) => {
    console.log(`useCollection - get document with id ${documentId}, collection: ${collectionName}`);

    if (!documentId) {
     throw new Error('documentId should be set'); 
    }

    const db = firebaseContext.firebase.db();
    const query = db.collection(collectionName).doc(documentId);
    const document = await query.get();
    if (!document.exists) {
      throw new Error("Document doesn't exists in the database.");
    } else {
      return document.data();
    }
  };
  
  const addOrUpdate = async (documentId, data) => {
    console.log(`useCollection - addOrUpdate document with id ${documentId}, collection: ${collectionName}`);     
    console.log(data);

    if (!data) {
      throw new Error('data should be set'); 
    }

    const isNewDocument = !documentId;  
    if (isNewDocument) {
      return add(data);
    } else {
      return set(documentId, false, data);
    }
  };
  
  const add = async (data) => {
    console.log(`useCollection - add: ${collectionName}`);

    if (!data) {
      throw new Error('data should be set'); 
    }
    
    const db = firebaseContext.firebase.db();
    const collection = db.collection(collectionName);

    const collectionHelper = new CollectionHelper(currentUser);
    collectionHelper.setCommonFields(true, data);
  
    return collection.add(data);
  };

  const addWithId = async (documentId, data) => {
    console.log(`useCollection - addWithId document with id ${documentId}, collection: ${collectionName}`);

    if (!documentId) {
      throw new Error('documentId should be set'); 
    }
     
    if (!data) {
      throw new Error('data should be set'); 
    }
    
    return set(documentId, true, data);
  };

  const toTimestamp = (date) => {
    return CollectionHelper.toTimestamp(date);
  };
  
  const set = async (documentId, isNewDocument, data) => {
    console.log(`useCollection - set document with id ${documentId}, collection: ${collectionName}`);

    if (!documentId && !isNewDocument) {
      throw new Error('documentId should be set'); 
    }
     
    if (!data) {
      throw new Error('data should be set'); 
    }

    const db = firebaseContext.firebase.db();
    const collection = db.collection(collectionName);

    const collectionHelper = new CollectionHelper(currentUser);
    collectionHelper.setCommonFields(isNewDocument, data);
  
    return collection.doc(documentId).set(data);
  };

  return { getAll, get, addOrUpdate, add, addWithId, toTimestamp };

};

export default useCollection;
