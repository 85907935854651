import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

interface AlertModalProps {
  open: boolean;
  title: string;
  text: string;
  onConfirm?: (() => void) | undefined;
  onCancel: (() => void);
  confirmButtonText?: string | undefined;
  cancelButtonText?: string | undefined;
}

const AlertModal = (props: AlertModalProps) => {

  const confirmButton = props.onConfirm ? (
    <button className="buttonish text-only" onClick={props.onConfirm}>{props.confirmButtonText ? props.confirmButtonText : 'Da'}</button>
  ) : null;

  const cancelButtonClassName = props.onConfirm ? "secondary" : null;

  return (
    <Dialog open={props.open} onClose={props.onCancel} className="modal-content">
      <div className="close-button" onClick={props.onCancel}>
        <CloseIcon />
      </div>
      <h1 className="title">{props.title}</h1>
      <div className="alert-modal-content">
        {props.text}
      </div>
      <div className="button-bottom-bar r-align">
        <button className={`buttonish text-only ${cancelButtonClassName}`} onClick={props.onCancel}>{props.cancelButtonText ? props.cancelButtonText : 'Ne'}</button>
        {confirmButton}
      </div>
    </Dialog>
  );
};

export default AlertModal;
