import React, { useState } from 'react';
import SelectBox from 'components/forms/SelectBox.js';

const DefaultInvoiceLiquidationDate = ({ name, defaultValue, register }) => {
  const [value, setValue] = useState(defaultValue);

  const getDaysOption = (days) => {
    return {
      value: days,
      label: days + ' dni'
    };
  };

  const options = [
    getDaysOption(15),
    getDaysOption(30),
    getDaysOption(45),
    getDaysOption(60)
  ];

  const handleChange = (selectedOption) => {
    setValue(selectedOption.value);
  };

  const selectedOption = getDaysOption(value);

  return (
    <>
      <SelectBox
      label="Privzet rok plačila"
      fullLine
      value={selectedOption}
      onChange={handleChange}
      options={options} />
      <input type="hidden" name={name} value={value} ref={register} />
    </>
  );
};

export default DefaultInvoiceLiquidationDate;
