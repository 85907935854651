import React from "react";
import { Link } from "react-router-dom";
import Router from "utils/Router";
import * as Routes from 'constants/Routes.js';
import SubscriptionInfoType from "types/SubscriptionInfoType";
import OrganizationSubscriptionSource from "types/OrganizationSubscriptionSource";
import WarningEmoji from 'emojis/warning_26a0-fe0f.png';

type SubscriptionInfoProps = {
  organizationId: string;
  infoType: SubscriptionInfoType;
};

const SubscriptionInfo = (props: SubscriptionInfoProps) => {

  const getInfoText = (infoType: SubscriptionInfoType) => {
    switch (infoType) {
      case SubscriptionInfoType.None:
        return null;
      case SubscriptionInfoType.NeedsPaymentInfoUpdate:
        return 'Pri plačevanju naročnine je prišlo do napake.';
      case SubscriptionInfoType.InvoicesCreatedLimitReached:
        return 'Dosegli ste limit dovoljenih računov na mesec.';
      default:
        throw new Error('Not supported info type');
    }
  }

  const getActionText = (infoType: SubscriptionInfoType) => {
    switch (infoType) {
      case SubscriptionInfoType.None:
        return null;
      case SubscriptionInfoType.NeedsPaymentInfoUpdate:
        return 'Uredi podatke o naročnini …';
      case SubscriptionInfoType.InvoicesCreatedLimitReached:
        return 'Nadgradite paket …';
      default:
        throw new Error('Not supported info type');
    }
  }

  const getSource = (infoType: SubscriptionInfoType) => {
    switch (infoType) {
      case SubscriptionInfoType.None:
        return null;
      case SubscriptionInfoType.NeedsPaymentInfoUpdate:
        return OrganizationSubscriptionSource.PaymentUpdate;
      case SubscriptionInfoType.InvoicesCreatedLimitReached:
        return OrganizationSubscriptionSource.LimitReached;
      default:
        throw new Error('Not supported info type');
    }
  }

  const infoText = getInfoText(props.infoType);
  const actionText = getActionText(props.infoType);
  const source = getSource(props.infoType);

  return (
    <div className="subscription-info">
      <img className="emoji top6" src={WarningEmoji} alt="warning" />
      <div className="gray-uppercase">
        {infoText}
        <Link className="edit-link" to={Router.route(Routes.organizationSubscriptions, { organizationId: props.organizationId }, { source: source })}>{actionText}</Link>
      </div>
    </div>
  )
};

export default SubscriptionInfo;
