import React, { useState } from 'react';
import OrganizationSelectBox from 'components/forms/OrganizationSelectBox.js';
import TaxNumberHelper from 'utils/TaxNumberHelper.js';
import PartnerEditModal from 'components/modals/PartnerEditModal.js';
import { ReactComponent as EditIcon } from 'icons/edit.svg';

const PartnerTextBox = ({ name, partnerData, onChanged, errors, register}) => {

  const [editPartner, setEditPartner] = useState(false);
  
  const onChange = (selectedOption) => {
    if (selectedOption) {
      if (selectedOption.organization) {
        onChanged(selectedOption.organization);
      } else {
        partnerData.name = selectedOption.label;
        onChanged(partnerData);
        setEditPartner(true);
      }
    }
    else {
      onChanged(null);
    }
  };

  const removeButtonClick = () => {
    onChanged(null);
  };

  const editButtonClick = () => {
    setEditPartner(true);
  };

  const onPartnerEditCancel = () => {
    setEditPartner(false);
  };

  const onPartnerEditSave = (partner) => {
    onChanged(partner);
    setEditPartner(false);
  };
  
  const selectBoxHtml = !partnerData.name ? (
    <OrganizationSelectBox name={name}
    value={partnerData.id}
    text={partnerData.name}
    register={register({ required: 'Partner mora biti izbran.' })}
    errors={errors}
    onChange={onChange}
    placeholder="Naziv ali davčna št. partnerja ..."
    searchGlobalOrganizations
    searchPartners
    addText="Ročno vnesi podatke o partnerju ..." />
  ) : null;

  const detailsHtml = partnerData.name ? (
    <div className="details">
      <div className="floaty remove-button" onClick={removeButtonClick}>&#10005;</div>
      <div className="floaty edit-button" onClick={editButtonClick}>
        <EditIcon />
      </div>
      <ul>
        <li>{partnerData.name}</li>
        <li>{partnerData.address}</li>
        <li>{partnerData.postOffice} {partnerData.city}</li>
        <li>{partnerData.country}</li>
        <li>{TaxNumberHelper.getTaxNumberString(partnerData.taxNumber)}</li>
      </ul>

      <input name={name + '.id'} defaultValue={partnerData.id} ref={register} type="hidden" />
      <input name={name + '.name'} defaultValue={partnerData.name} ref={register} type="hidden" />
      <input name={name + '.address'} defaultValue={partnerData.address} ref={register} type="hidden" />
      <input name={name + '.postOffice'} defaultValue={partnerData.postOffice} ref={register} type="hidden" />
      <input name={name + '.city'} defaultValue={partnerData.city} ref={register} type="hidden" />
      <input name={name + '.country'} defaultValue={partnerData.country} ref={register} type="hidden" />
      <input name={name + '.taxNumber'} defaultValue={partnerData.taxNumber} ref={register} type="hidden" />
      <input name={name + '.countryIssuedId'} defaultValue={partnerData.countryIssuedId} ref={register} type="hidden" />
      <input name={name + '.email'} defaultValue={partnerData.email} ref={register} type="hidden" />
      <input name={name + '.glnCode'} defaultValue={partnerData.glnCode} ref={register} type="hidden" />
      <input name={name + '.bankAccount'} defaultValue={partnerData.bankAccount} ref={register} type="hidden" />
      <input name={name + '.bankBusinessIdentifierCode'} defaultValue={partnerData.bankBusinessIdentifierCode} ref={register} type="hidden" />
    </div>
  ) : null;

  return (
    <div className="partner-text-box">
      {selectBoxHtml}
      {detailsHtml}
      <PartnerEditModal open={editPartner} data={partnerData} onClose={onPartnerEditCancel} onSave={onPartnerEditSave} />
    </div>
  );
};

export default PartnerTextBox;
