import React, { useState } from 'react';
import SnackbarAlert from 'components/SnackbarAlert.js';

const useSnackbar = () => {
  const [snackbarText, setSnackbarText] = useState(null);
  const [autoHideDuration, setAutoHideDuration] = useState(undefined);

  const showSaveSuccess = () => {
    setAutoHideDuration(undefined);
    show({ text: 'Spremembe uspešno shranjene.', severity: 'success' });
  };
  
  const show = (data, duration) => {
    setAutoHideDuration(duration ? duration : undefined);
    setSnackbarText(data);
  };

  const close = () => {
    setSnackbarText(null);
  };

  const render = () => {
    return (
      <SnackbarAlert content={snackbarText} handleClose={close} duration={autoHideDuration} />
    )
  };

  return {
    show,
    showSaveSuccess,
    render
  };

};

export default useSnackbar;
