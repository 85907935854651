import React from 'react';
import { ReactComponent as ReceiptIcon } from 'icons/receipt.svg';

type EmptyViewProps = {
  text: string;
  button?: any;
}

const EmptyView = (props: EmptyViewProps) => {

  return (
    <div className="empty-view">
      <ReceiptIcon />
      <h2>{props.text}</h2>
      {props.button}
    </div>
  );

};

export default EmptyView;
