const useValidation = (errors) => {
  return getErrorText(errors);
};

const getErrorText = (errors) => {
  const validationErrors = Object.keys(errors);
  if (validationErrors.length === 1) {
    const key = validationErrors[0];
    return errors[key].message;
  }

  if (validationErrors.length > 1) {
    return 'Izpolnite manjkajoče podatke.';
  }

  return '';
}

export default useValidation;
