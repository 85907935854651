import React from 'react';
import useEmailHistory from 'hooks/useEmailHistory';
import EmailHistoryEvent from './EmailHistoryEvent';

type EmailHistoryModalFormProps = {
  invoiceId: string,
  messageIds: string[],
  onClose: () => void
}

const EmailHistoryModalForm = (props: EmailHistoryModalFormProps) => {
  
  const { emailEvents } = useEmailHistory(props.invoiceId, props.messageIds);
  
  const close = async () => {   
    props.onClose();
  }

  const getContent = () => {
    if (emailEvents === undefined) {
      return 'Nalagam ...'
    }

    if (emailEvents.length === 0) {
      return 'Zgodovina pošiljanj je prazna.'
    }

    const header = (
      <tr>
        <th className="w-padding">Dogodek</th>
        <th>Čas dogodka</th>
        <th>Zadeva</th>
        <th>Prejemnik</th>
      </tr>
    );

    const rows = emailEvents.map((emailEvent, index) => (
      <EmailHistoryEvent key={index} emailEvent={emailEvent} />
    ));

    return (
      <table className="black-header-table">
        <thead>
          {header}
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  return (
    <>
      {getContent()}
      <div className="button-bottom-bar r-align">
        <button className="buttonish text-only" type="button" onClick={close}>V redu</button>
      </div>
    </>
  );
};

export default EmailHistoryModalForm;
