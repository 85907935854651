import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import PaymentHistoryModalForm from './PaymentHistoryModalForm';
import PaymentInfo from 'types/PaymentInfo';
import InvoiceStatus from 'types/InvoiceStatus';
import TabControl from 'components/forms/TabControl';
import EmailHistoryModalForm from './EmailHistoryModalForm';

type InvoiceHistoryModalProps = {
  open: boolean;
  payments?: PaymentInfo[] | undefined;
  onPaymentHistorySave: (newStatus: InvoiceStatus | null) => void;
  onClose: () => void;
  invoiceId: string;
  emailMessageIds: string[],
  priceSum: number;
  currency: string;
};

const InvoiceHistoryModal = (props: InvoiceHistoryModalProps) => {

  const [saving, setSaving] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const tryClose = () => {
    if (saving) {
      return;
    }

    props.onClose();
  }

  const handleSaveSuccess = (newStatus: InvoiceStatus | null) => {
    props.onPaymentHistorySave(newStatus);
    tryClose();
  }

  const getContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <PaymentHistoryModalForm
          payments={props.payments}
          priceSum={props.priceSum}
          currency={props.currency}
          invoiceId={props.invoiceId}
          saving={saving}
          onSavingChanged={setSaving}
          onSaveSuccess={handleSaveSuccess} />
        )
      case 1:
        return (
          <EmailHistoryModalForm
          invoiceId={props.invoiceId}
          messageIds={props.emailMessageIds}
          onClose={tryClose}
          />
        )
    }
  }

  return (
    <Dialog open={props.open} onClose={tryClose} className="modal-content">
      <div className="close-button" onClick={tryClose}>
        <CloseIcon />
      </div>
      <h1 className="title">Zgodovina</h1>
      <TabControl selectedIndex={tabIndex} options={["Plačila", "E-pošta"]} onSelectedIndexChanged={setTabIndex} />
      {props.open ? getContent() : null}
    </Dialog>
  );
};

export default InvoiceHistoryModal;
