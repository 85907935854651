class PaymentReferenceProvider {

  static getPaymentReference(invoiceNumber: number, invoiceNumberYear: number) {
    const paymentReference = 'SI00 ' + invoiceNumber + '-' + invoiceNumberYear;
    return paymentReference;
  }

}

export default PaymentReferenceProvider;
