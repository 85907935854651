class FileDownloader {
  
  static downloadFile(file: Blob, fileName: string) {
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

}

export default FileDownloader;
