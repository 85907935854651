import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useValidation from 'hooks/useValidation.js';
import TextBox from 'components/forms/TextBox.js';
import CheckBox from 'components/forms/CheckBox.js';
import InvoiceAttachment from 'components/documents/invoice/InvoiceAttachment';
import Bugsnag from '@bugsnag/js';
import EmailAttachment from 'types/EmailAttachment';
import EmailModalAttachment from 'types/EmailModalAttachment';
import { useAuth } from 'utils/Auth';
import CreatableBox, { CreatableOption } from 'components/forms/CreatableBox';
import FormHelper from 'utils/FormHelper';
import EmailSentResponse from 'types/EmailSentResponse';

type EmailModalFormProps = {
  onSent: (messageIds: string[], recipient: string[]) => void;
  sender: string;
  recipients: string[];
  subject: string;
  body: string;
  attachments: EmailModalAttachment[],
  getPdfAttachment: () => EmailAttachment;
  getZipAttachment: () => EmailAttachment;
  downloadPdfAttachment: () => void;
  downloadZipAttachment: () => void;
  accountingEmail?: string | undefined;
  invoiceId: string;
  sending: boolean;
  onSendingChanged: (sending: boolean) => void;
};

const EmailModalForm = (props: EmailModalFormProps) => {

  const { register, errors, handleSubmit } = useForm();
  const { createBackendRequest } = useAuth();
  const validationErrors = useValidation(errors);
  const [sendError, setSendError] = useState<string | null>(null);
  const [recipientEmailValidationError, setRecipientEmailValidationError] = useState<string | null>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<EmailModalAttachment[]>([...props.attachments]);

  const defaultRecipients: CreatableOption[] = props.recipients ? props.recipients.map(recipient => {
    return {
      label: recipient,
      value: recipient
    };
  }) : [];
  const [selectedRecipients, setSelectedRecipients] = useState<CreatableOption[]>(defaultRecipients);
  
  const validateEmail = (email: String) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const send = useCallback(async (formData) => {

    const getBccList = (formData: any) => {
      let list = [];
  
        if (formData.ccAccounting) {
          list.push(props.accountingEmail);
        }
  
        if (formData.ccMyself) {
          list.push(props.sender);
        }
  
        return list;
    };

    const recipients = selectedRecipients.map(x => x.value);
    if (recipients.length === 0) {
      setRecipientEmailValidationError('V polje prejemnik vnesite e-naslov, na katerega želite poslati račun.');
      return;
    }

    const invalidEmails = recipients.filter(recipient => !validateEmail(recipient));
    if (invalidEmails.length === 1) {
      setRecipientEmailValidationError(`E-mail naslova ${invalidEmails[0]} v polju prejemnik ni bilo mogoče prepoznati. Vnesite pravilen e-mail naslov.`);
      return;
    }

    if (invalidEmails.length > 1) {
      setRecipientEmailValidationError(`E-mail naslovov ${invalidEmails.join(', ')} v polju prejemnik ni bilo mogoče prepoznati. Vnesite pravilne e-mail naslove.`);
      return;
    }

    setRecipientEmailValidationError(null);
    setSendError(null);
    props.onSendingChanged(true);
    
    const pdfAttachment = await props.getPdfAttachment();
    const zipAttachment = await props.getZipAttachment();

    try {
      let attachments: EmailAttachment[] = [];

      selectedAttachments.forEach(attachment => {
        if (attachment.isMainPdf) {
          attachments.push(pdfAttachment);
        }
        else if (attachment.isMainZip) {
          attachments.push(zipAttachment);
        }
        else {
          attachments.push({
            name: attachment.file.fileName,
            url: attachment.file.url
          });
        }
      });

      const bccList = getBccList(formData);
      const requestBody = {
        sender: {
          email: props.sender
        },
        bcc: bccList,
        recipients: recipients,
        subject: formData.subject,
        body: formData.body,
        attachments: attachments
      };

      const axiosInstance = createBackendRequest();
      const url = '/invoices/' + props.invoiceId + '/email';
      const response = await axiosInstance.post(url, requestBody);
      const emailSentResponse = response.data as EmailSentResponse;
      props.onSent(emailSentResponse.messageIds, recipients);
    }
    catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.log(`Error while sending email: ${error}`);
      setSendError('Pri pošiljanju e-pošte je prišlo do napake.');
    }

    props.onSendingChanged(false);
  }, [props, selectedAttachments, createBackendRequest, selectedRecipients]);
  
  const deselectAttachment = (attachmentToDelete: EmailModalAttachment) => {
    setSelectedAttachments(selectedAttachments.filter(attachment => attachment !== attachmentToDelete));
  };

  const attachmentsHtml = selectedAttachments.map((attachment, index) => {
    let onClick;

    if (attachment.isMainPdf) {
      onClick = props.downloadPdfAttachment;
    }

    if (attachment.isMainZip) {
      onClick = props.downloadZipAttachment;
    }

    const handleRemoveButtonClicked = !attachment.isMainPdf ? (removedAttachmentId: string) => {
      deselectAttachment(attachment);
    } : undefined;

    return (
     <InvoiceAttachment key={attachment.file.id} id={attachment.file.id} url={attachment.file.url} fileName={attachment.file.fileName} onClick={onClick} onRemoveButtonClicked={handleRemoveButtonClicked} />
    )
  });

  const ccAccountingDescription = props.accountingEmail ? props.accountingEmail : "E-mail vašega računovodstva lahko vnesete v nastavitvah.";

  return (
    <form onSubmit={handleSubmit(send)} onKeyDown={(e) => FormHelper.preventSubmitOnEnterKey(e)}>
      <div className="email-form">
        <CreatableBox autoFocus label="Prejemnik" value={selectedRecipients} setValue={setSelectedRecipients} placeholder="E-mail naslov prejemnika ..." validationFailed={recipientEmailValidationError !== null} />
        <TextBox name="subject" className="full" fullLine type="text" label="Zadeva" defaultValue={props.subject} ref={register} />
        <TextBox name="body" className="full high" fullLine multiLine type="text" height="140px" label="Sporočilo" defaultValue={props.body} ref={register} />
        <CheckBox name="ccAccounting" className="inline-checkbox" fullLine label="Pošlji kopijo računa v računovodstvo" disabled={!props.accountingEmail} description={ccAccountingDescription} ref={register} />
        <CheckBox name="ccMyself" className="inline-checkbox" fullLine label="Pošlji kopijo na moj e-mail" description={props.sender} ref={register} />
      </div>
      <div className="button-bottom-bar invoice-attachment-list">
        {attachmentsHtml}
      </div>
      <div className="button-bottom-bar error">
        {validationErrors}
        {recipientEmailValidationError}
        {sendError}
      </div>
      <div className="button-bottom-bar r-align">
        <button className="buttonish text-only" type="submit" disabled={props.sending}>Pošlji</button>
      </div>
    </form>
  );
};

export default EmailModalForm;
