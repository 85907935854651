import React from 'react';
import SelectBox from './SelectBox';
import CurrencyProvider from 'utils/CurrencyProvider';

type CurrencyBoxProps = {
  label: string;
  onChange: (event: string) => void;
  value: string;
  fullLine?: boolean | undefined;
}

const CurrencyBox = (props: CurrencyBoxProps) => {

  interface CurrencyOption {
    label: string;
    value: string;
  }

  const handleChange = (option: CurrencyOption) => {
    if (!props.onChange) {
      return;
    }

    const currency = option.value;
    props.onChange(currency);
  };
  
  const options = CurrencyProvider.getAllCurrencies();

  const getSelectedOption = (currency: string) => {
    const selectedOptions = options.filter(x => x.value === currency);

    if (selectedOptions.length > 0) {
      return selectedOptions[0];
    }

    throw new Error(`No currency found: ${currency}`);
  }

  const selectedOption = getSelectedOption(props.value.trim());
  
  return (    
    <SelectBox
    label={props.label}
    visible={true}
    value={selectedOption}
    options={options}
    onChange={handleChange}
    components={undefined}
    className={undefined}
    fullLine={props.fullLine}
    isClearable={undefined}
    style={undefined}
    defaultMenuIsOpen={undefined} />
  );
};

export default CurrencyBox;
