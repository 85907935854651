import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'utils/Auth';
import * as Routes from 'constants/Routes.js';

const AuthenticatedRoute = ({ component: RouteComponent, ...rest }) => {
  const currentUser = useAuth().currentUser;
  const canRender = !!currentUser || !rest.private;
  return (
    <Route
      {...rest}
      render={props => 
        canRender ? (
          <RouteComponent {...props} />
        ) : (
          <Redirect to={Routes.login} />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
