import React from 'react';
import SelectBox from 'components/forms/SelectBox';
import DateAndTextOption from './DateAndTextOption';

type InvoiceServiceDateProps = {

  value: number,
  
  visible: boolean,

  onChange: (dates: Date[] | undefined, index: number | undefined) => void;
    
  validationFailed: string,

};

type InvoiceServiceDateOption = {

  label: string;
  
  value: Date[] | undefined;
  
  index: number;

}

const InvoiceServiceDate = (props: InvoiceServiceDateProps) => {

  const getSingleDate = (daysToAdd: number) => {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return [date, date];
  }

  const getValue = (index: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
        return getSingleDate(-index);
      case 3: {
        const currentDate = new Date();
        const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);        
        const toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        return [fromDate, toDate];
      }
      case 4: {
        const currentDate = new Date();
        const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);        
        const toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        return [fromDate, toDate];
      }
      case 5:
        return undefined;
      default:
        throw new Error('Not supported: ' + index);
    }
  };
  
  const getLabel = (index: number) => {
    switch (index) {
      case 0:
        return 'Danes';
      case 1:
        return 'Včeraj';
      case 2:
        return 'Predvčerajšnjim';
      case 3:
        return 'Trenutni mesec';
      case 4:
        return 'Prejšnji mesec';
      case 5:
        return 'Poljubno ...';        
      default:
        throw new Error('Not supported: ' + index);
    }
  };
  
  const getSubtitle = (value: Date[] | undefined) => {
    if (!value) {
      return undefined;
    }

    if (value[0] === value[1]) {
      return value[0].toLocaleDateString();
    }

    return `${value[0].toLocaleDateString()} - ${value[1].toLocaleDateString()}`;
  };

  const getOptionByIndex = (index: number) => {
    const label = getLabel(index);
    const value = getValue(index);
    const subtitle = getSubtitle(value);
    return {
      value: value,
      label: label,
      index: index,
      date: subtitle
    }
  };

  const options = [
    getOptionByIndex(0),
    getOptionByIndex(1),
    getOptionByIndex(2),
    getOptionByIndex(3),
    getOptionByIndex(4),
    getOptionByIndex(5)
  ];

  const selectedOption = options[props.value];

  const handleSelectedOptionChange = (option: InvoiceServiceDateOption) => {
    props.onChange(option.value, option.index);
  };

  return (
    <SelectBox
    label="Datum storitve"
    visible={props.visible}
    value={selectedOption}
    options={options}
    onChange={handleSelectedOptionChange}
    components={{ Option: DateAndTextOption }}
    className={undefined}
    fullLine={undefined}
    isClearable={undefined}
    style={undefined}
    defaultMenuIsOpen={undefined} />
  );
};

export default InvoiceServiceDate;
