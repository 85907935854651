import { useEffect, useState } from 'react';
import { useFirebase } from 'config/Firebase';
import useCollection from 'hooks/useCollection.js';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import InvoiceAttributionProvider from 'utils/InvoiceAttributionProvider';
import CurrencyProvider from 'utils/CurrencyProvider';
import DefaultVatPercentageProvider from "utils/DefaultVatPercentageProvider";
import useInvoiceMetadata from 'hooks/useInvoiceMetadata';
import useInvoiceButtonState from 'hooks/useInvoiceButtonState';
import useInvoiceQrCode from 'hooks/useInvoiceQrCode';
import useInvoiceItemListState from 'hooks/useInvoiceItemListState';
import Bugsnag from '@bugsnag/js';
import InvoiceSaver from 'utils/InvoiceSaver';
import InvoiceTypeProvider from 'utils/InvoiceTypeProvider';
import InvoiceTypeLocalizer from 'utils/InvoiceTypeLocalizer';
import InvoiceAttachmentMapper from 'mappers/InvoiceAttachmentMapper';
import InvoiceDataProvider from 'providers/InvoiceDataProvider';
import InvoiceProgressTextProvider from 'utils/InvoiceProgressTextProvider';
import InvoiceFileNameProvider from 'utils/InvoiceFileNameProvider';

const useInvoice = (invoiceId, organizationId, invoiceTypeId, currentUser, fromInvoiceId) => {
  
  const [invoiceData, setInvoiceData] = useState(null);
  const [loadedInvoiceId, setLoadedInvoiceId] = useState(undefined); // can be either <undefined | null | string>
  const [loadedOrganizationId, setLoadedOrganizationId] = useState(undefined); // can be either <undefined | null | string>
  const [partnerData, setPartnerData] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [hasData, setHasData] = useState(false);
  const [saving, setSaving] = useState(false);
  const [progressText, setProgressText] = useState(null); // <string | null>
  const metadata = useInvoiceMetadata();
  const buttonState = useInvoiceButtonState();
  const itemListState = useInvoiceItemListState();
  const firebaseContext = useFirebase();
  const db = firebaseContext.firebase.db();
  const qr = useInvoiceQrCode(invoiceData, metadata.invoiceNumberText, itemListState.summary);
  const requestInvoiceId = invoiceId ? invoiceId : fromInvoiceId;

  const invoicesCollection = useCollection('invoices');
  const partnersCollection = useCollection('partners');
  
  const isNewInvoice = !invoiceId;

  const validate = () => {
    return metadata.validate();
  };

  const save = async (formData) => {
    setSaving(true);

    const data = getFormData(formData);

    try {
      if (isNewInvoice) {
        firebaseContext.firebase.logEvent('new_item', { item_category: 'documents' });
      }

      const saver = new InvoiceSaver(firebaseContext.firebase.db(), currentUser, invoiceId, data, organizationId, organizationData.lastInvoices, fromInvoiceId);
      const id = await saver.save();

      setPartnerData(data.partner);

      metadata.setServiceDateRangeVisible(true);
      metadata.setLiquidationDateDaysVisible(false);
      metadata.setStatusVisible(true);
      metadata.setNextInvoiceNumberEditable(false);
      metadata.setInvoiceNumberEditable(false);

      // Get rid of the attachments which were not uploaded successfully.
      metadata.setAttachments(metadata.getValidAttachments());
    
      return {
        id: id,
        data: data
      };
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log('Error while saving invoice: ' + error);
      return null;
    } finally {
      setSaving(false);
    }
  };

  const getFileName = (extension) => {
    return InvoiceFileNameProvider.getFileName(metadata.invoiceType.id, invoiceData.invoiceNumber, metadata.invoiceNumberYear, extension);
  };

  const getEmailTemplate = () => {
    if (!hasData) {
      return {};
    }

    const invoiceType = InvoiceTypeLocalizer.getText(metadata.invoiceType.id);
    const attachments = getEmailAttachments();
    return {
      sender: currentUser.email,
      recipients: partnerData.email ? [partnerData.email] : [],
      subject: `${invoiceType} št. ${metadata.invoiceNumberText}`,
      body: `Pozdravljeni,\n\nv prilogi vam pošiljamo ${invoiceType.toLocaleLowerCase()} št. ${metadata.invoiceNumberText}.\n\nLep pozdrav,\n${invoiceData.signature}`,
      attachments: attachments
    };
  };

  const getEmailAttachments = () => {
    if (!hasData) {
      return [];
    }

    let emailAttachments = [];

    const pdfFileName = getFileName('pdf');
    emailAttachments.push({
      file: {
        id: 'pdf',
        fileName: pdfFileName
      },
      isMainPdf: true
    });

    const zipFileName = getFileName('zip');
    emailAttachments.push({
      file: {
        id: 'xml',
        fileName: zipFileName
      },
      isMainZip: true
    });

    if (metadata.attachments) {
      metadata.attachments.forEach(attachmentFile => {
        emailAttachments.push({
          file: attachmentFile
        });
      });
    }
    
    return emailAttachments;
  };

  const saveEmailRecipientOnPartner = async (emailRecipient) => {
    const partnerId = invoiceData.partner.id;
    const existingPartner = await partnersCollection.get(partnerId);
    existingPartner.email = emailRecipient;
    partnersCollection.addOrUpdate(partnerId, existingPartner);
  };

  const saveInvoiceAfterEmailSent = async (messageIds, emailRecipients) => {
    const data = invoiceData;

    // Set message ids
    if (!invoiceData.sentEmailMessageIds) {
      invoiceData.sentEmailMessageIds = messageIds;
    } else {
      messageIds.forEach(messageId => {
        invoiceData.sentEmailMessageIds.push(messageId);
      });
    }

    // Mark invoice as sent
    if (invoiceData.status === 'Sent') {
      metadata.setStatus('SentByEmail');
      data.status = 'SentByEmail';
    }

    // Change partner on the invoice
    const emailRecipient = emailRecipients[0];
    invoiceData.partner.email = emailRecipient;
    setPartnerData(invoiceData.partner);
    
    setInvoiceData(data);

    invoicesCollection.addOrUpdate(invoiceId, data);

    saveEmailRecipientOnPartner(emailRecipient);
  };

  const getFormData = (formData) => {
    const data = invoiceData ? invoiceData : {};
    data.invoiceNumber = metadata.invoiceNumber;
    data.invoiceNumberYear = metadata.invoiceNumberYear;
    data.invoiceNumberPrefixText = metadata.invoiceNumberPrefixText;

    data.dateCreated = invoicesCollection.toTimestamp(metadata.dateCreated);
    data.serviceDate = invoicesCollection.toTimestamp(metadata.startServiceDate);
    data.endServiceDate = invoicesCollection.toTimestamp(metadata.endServiceDate);
    data.liquidationDate = invoicesCollection.toTimestamp(metadata.liquidationDate);
    
    data.currency = metadata.currency;
    data.status = metadata.status;
    data.attribution = metadata.attribution;
    data.invoiceTypeId = metadata.invoiceType.id;
    data.attachments = InvoiceAttachmentMapper.toInvoiceAttachmentDatas(metadata.getValidAttachments());
    data.signature = formData.signature;
    data.signatureFileUrl = formData.signatureFileUrl;

    data.items = itemListState.getItems();

    data.partner = {};
    data.partner.id = formData.partner.id;
    data.partner.name = formData.partner.name;
    data.partner.address = formData.partner.address;
    data.partner.postOffice = formData.partner.postOffice;
    data.partner.city = formData.partner.city;
    data.partner.country = formData.partner.country;
    data.partner.taxNumber = formData.partner.taxNumber;
    data.partner.countryIssuedId = formData.partner.countryIssuedId;
    data.partner.email = formData.partner.email;
    data.partner.glnCode = formData.partner.glnCode;
    data.partner.bankAccount = formData.partner.bankAccount;
    data.partner.bankBusinessIdentifierCode = formData.partner.bankBusinessIdentifierCode;

    data.organization = {};
    data.organization.id = metadata.organizationId;
    data.organization.name = formData.organization.name;
    data.organization.address = formData.organization.address;
    data.organization.postOffice = formData.organization.postOffice;
    data.organization.city = formData.organization.city;
    data.organization.country = formData.organization.country;
    data.organization.taxNumber = formData.organization.taxNumber;
    data.organization.bankAccount = formData.organization.bankAccount;
    data.organization.accountingEmail = formData.organization.accountingEmail;
    data.organization.email = formData.organization.email;
    data.organization.phoneNumber = formData.organization.phoneNumber;
    data.organization.countryIssuedId = formData.organization.countryIssuedId;
    data.organization.bankBusinessIdentifierCode = formData.organization.bankBusinessIdentifierCode;

    data.taxDescription = itemListState.taxDescription;
    data.taxReverseCharge = itemListState.taxReverseCharge;

    data.timeZoneOffset = new Date().getTimezoneOffset();

    return data;
  };
  
  useEffect(() => {
    const getData = async () => {
      console.log(`Start loading invoice: ${requestInvoiceId}`);
      const data = await new InvoiceDataProvider(firebaseContext.firebase.db(), invoiceTypeId).get(requestInvoiceId, organizationId);
      console.log(`Loaded invoice: ${requestInvoiceId}`);

      setLoadedInvoiceId(requestInvoiceId);
      setLoadedOrganizationId(organizationId);
      setInvoiceData(data);
      setPartnerData(data.partner);
      setOrganizationData(data.organization);

      const invoiceNumber = data.invoiceNumber;
      metadata.setInvoiceNumber(invoiceNumber);

      metadata.setInvoiceNumberYear(data.invoiceNumberYear);

      metadata.setInvoiceNumberPrefixText(data.invoiceNumberPrefixText);

      const dateCreated = data.dateCreated.toDate();
      metadata.setDateCreated(dateCreated);

      const invoiceNumberText = InvoiceNumberProvider.getInvoiceNumberText(invoiceNumber, data.invoiceNumberYear, data.invoiceNumberPrefixText);
      metadata.setInvoiceNumberText(invoiceNumberText);
      
      const attribution = InvoiceAttributionProvider.getAttributionText(data.attribution, data.invoiceNumber, data.invoiceNumberYear, data.organization.bankAccount);
      metadata.setAttribution(attribution);

      const invoiceType = InvoiceTypeProvider.getById(data.invoiceTypeId);
      metadata.setInvoiceType(invoiceType);

      metadata.setAttachments(data.attachments ? InvoiceAttachmentMapper.toInvoiceAttachmentFiles(data.attachments) : []);

      const invoiceNumberLabelText = InvoiceTypeLocalizer.getInvoiceNumberLabelText(invoiceType.id);
      metadata.setInvoiceNumberLabelText(invoiceNumberLabelText);

      metadata.setStartServiceDate(data.serviceDate.toDate());
      metadata.setEndServiceDate(data.endServiceDate ? data.endServiceDate.toDate() : data.serviceDate.toDate());
      metadata.setLiquidationDate(data.liquidationDate.toDate());
      metadata.setCurrency(CurrencyProvider.getValidCurrency(data.currency));
      metadata.setStatus(data.status);
      metadata.setOrganizationId(data.organization.id);
      
      const vatPercentageDefault = DefaultVatPercentageProvider.getDefaultVatPercentage(data.organization);
      itemListState.init(data.items, vatPercentageDefault, data.taxDescription);

      metadata.setInvoiceNumberEditable(isNewInvoice);
      metadata.setNextInvoiceNumberEditable(isNewInvoice);

      const isNewStandaloneInvoice = isNewInvoice && !fromInvoiceId;
      metadata.setLiquidationDateDaysVisible(isNewStandaloneInvoice);

      if (isNewStandaloneInvoice) {
        const defaultLiquidationDays = data.organization.liquidationDateDays ? parseInt(data.organization.liquidationDateDays) : 30;
        metadata.setLiquidationDateDays(defaultLiquidationDays);

        metadata.setLiquidationDateWithDateAndDays(dateCreated, defaultLiquidationDays);
      }
      
      metadata.setServiceDateRangeVisible(!isNewInvoice || fromInvoiceId);  
      metadata.setStatusVisible(!isNewStandaloneInvoice);

      const fromInvoiceNumberText = data.fromInvoiceNumber && data.fromInvoiceNumberYear && data.fromInvoiceNumberPrefixText ? InvoiceNumberProvider.getInvoiceNumberText(data.fromInvoiceNumber, data.fromInvoiceNumberYear, data.fromInvoiceNumberPrefixText) : null;
      metadata.setFromInvoiceNumberText(fromInvoiceNumberText);
      
      setProgressText(null);
      setHasData(true);
    };

    const shouldLoad = !progressText && (loadedInvoiceId !== requestInvoiceId || loadedOrganizationId !== organizationId);
    if (shouldLoad) {
      getData();
      setProgressText(InvoiceProgressTextProvider.getText(invoiceId, fromInvoiceId));
    }

  }, [progressText, requestInvoiceId, invoiceId, loadedInvoiceId, loadedOrganizationId, invoiceTypeId, isNewInvoice, fromInvoiceId, firebaseContext.firebase, organizationId, invoiceData, organizationData, itemListState, metadata]);
    
  return {
    validate,
    save,
    partnerData,
    setPartnerData,
    organizationData,
    setOrganizationData,
    data: invoiceData,
    metadata,
    itemListState,
    hasData,
    setHasData,
    getFileName,
    isNewInvoice,
    getEmailTemplate,
    saveInvoiceAfterEmailSent,
    saving,
    buttonState,
    progressText,
    db,
    qr
  };

};

export default useInvoice;
