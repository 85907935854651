import { useLocation } from 'react-router-dom';

const useQueryParam = (name) => {

  const queryParams = new URLSearchParams(useLocation().search);
  return queryParams.get(name);

};

export default useQueryParam;
