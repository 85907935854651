import Router from 'utils/Router';
import * as Routes from 'constants/Routes.js';
import OrganizationSubscriptionSource from "types/OrganizationSubscriptionSource";
import { AxiosInstance } from 'axios';

class CheckoutSessionClient {
  
  private organizationId: string;

  private axiosInstance: AxiosInstance;

  constructor(organizationId: string, axiosInstance: AxiosInstance) {
    this.organizationId = organizationId;
    this.axiosInstance = axiosInstance;
  }

  createCheckoutSession = async (priceId: string) => {
    const successUrl = process.env.REACT_APP_ENDPOINT_URL + Router.route(Routes.organizationSubscriptions, { organizationId: this.organizationId }, { source: OrganizationSubscriptionSource.CheckoutSuccess });
    const cancelUrl = process.env.REACT_APP_ENDPOINT_URL + Router.route(Routes.organizationSubscriptions, { organizationId: this.organizationId }, { source: OrganizationSubscriptionSource.CheckoutCancel });
    const body = {
      organizationId: this.organizationId,
      priceId: priceId,
      successUrl: successUrl,
      cancelUrl: cancelUrl
    };
    const result = await this.axiosInstance.post('/create-checkout-session', body);
    console.log(`Create checkout session successful, got data: ${result.data}`);
    return result.data.url;
  }

}

export default CheckoutSessionClient;
