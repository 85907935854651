const getText = (status) => {
  if (status === 'Paid') {
    return 'Plačano';
  }

  if (status === 'PartiallyPaid') {
    return 'Delno plačano';
  }

  if (status === 'Sent') {
    return 'Izdano';
  }

  if (status === 'Reversed') {
    return 'Stornirano';
  }

  if (status === 'SentByEmail') {
    return 'Poslano';
  }

  return status;
};

export default { getText };
