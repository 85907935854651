import Base64Provider from "./Base64Provider";

class XmlHelper {

  static getTextAsString = (text: string, maximumNumberOfCharacters: number) => {
    if (!text) {
      return text;
    }
  
    return text.substring(0, maximumNumberOfCharacters);
  }

  static getIban = (text: string) => {  
    return text.replace(/ +/g, '');
  }
  
  static getNumberAsString = (number: number) => {
    if (!number) {
      return Number(0).toFixed(2);
    }
  
    return Number(number).toFixed(2);
  }
  
  static getBankBusinessIdentifierCode = (bankBusinessIdentifierCode: string | undefined) => {
    if (!bankBusinessIdentifierCode) {
      return '';
    }
  
    while (bankBusinessIdentifierCode.length < 11) {
      bankBusinessIdentifierCode += 'X';
    }
  
    return bankBusinessIdentifierCode;
  }
  
  static getTaxNumberAsString = (taxNumber: string | undefined) => {
    if (!taxNumber) {
      return taxNumber;
    }
  
    if (!taxNumber.startsWith('SI')) {
      return 'SI' + taxNumber;
    }
  
    return taxNumber;
  }
  
  static dateString = (dateWithTimeZone: Date) => {
    const utcDate = new Date(Date.UTC(dateWithTimeZone.getFullYear(), dateWithTimeZone.getMonth(), dateWithTimeZone.getDate(), 0, 0, 0));
    return utcDate.toISOString().slice(0, 10);
  }

  static dateTimeString(dateWithTimeZone: Date) {
    return dateWithTimeZone.toISOString().replace('Z', '');
  }

  static getXmlBase64(xmlContent: string) {
    const blob = XmlHelper.getXmlBlob(xmlContent);
    return Base64Provider.getBase64String(blob);
  }

  static getXmlBlob(xmlContent: string) {
    return new Blob([xmlContent], { type: 'text/xml' });
  }

}

export default XmlHelper;
