import React from 'react';
import EmailEvent from 'types/EmailEvent';
import EmailEventType from 'types/EmailEventType';
import { useAuth } from 'utils/Auth';

type EmailHistoryEventProps = {
  emailEvent: EmailEvent;
}

const EmailHistoryEvent = (props: EmailHistoryEventProps) => {

  const { organizationData } = useAuth();

  const getEventTypeString = (emailEventType: EmailEventType) => {
    switch (emailEventType) {
      case EmailEventType.Bounce:
        return 'Odklonjen';
      case EmailEventType.Delivered:
        return 'Prispel k prejemniku';
      case EmailEventType.Spam:
        return 'Uvrščen v neželeno pošto';
      case EmailEventType.Requests:
        return 'Poslan';
      case EmailEventType.Opened:
        return 'Odprt';
      case EmailEventType.FirstOpened:
        return 'Prvič odprt';
      case EmailEventType.Clicks:
        return 'Klik';
      case EmailEventType.Invalid:
        return 'Invalid';
      case EmailEventType.Deferred:
        return 'Odložen';
      case EmailEventType.Blocked:
        return 'Blokiran';
      case EmailEventType.Unsubscribed:
        return 'Odjavljen';
      case EmailEventType.Error:
        return 'Napaka';
      case EmailEventType.LoadedByProxy:
        return 'Prejet';
      case EmailEventType.Unknown:
        return 'Nepoznan';
      default:
        return '';
    }
  }

  const getBadgeClassName = (emailEventType: EmailEventType) => {
    switch (emailEventType) {
      case EmailEventType.Bounce:
      case EmailEventType.Spam:
      case EmailEventType.Invalid:
      case EmailEventType.Deferred:
      case EmailEventType.Blocked:
      case EmailEventType.Unsubscribed:
      case EmailEventType.Error:
        return 'red';
      case EmailEventType.Delivered:
      case EmailEventType.FirstOpened:
        return 'green';
      default:
        return '';
    }
  }

  const badgeClassName = `badge ${getBadgeClassName(props.emailEvent.eventType)}`;
  
  return (
    <tr className="email-history-event">
      <td className="w-badge"><span className={badgeClassName} title={props.emailEvent.bounceReason}>{getEventTypeString(props.emailEvent.eventType)}</span></td>
      <td data-label="Čas dogodka">{new Date(props.emailEvent.date).toLocaleString(organizationData.cultureCode)}</td>
      <td data-label="Zadeva">{props.emailEvent.subject}</td>
      <td data-label="Prejemnik">{props.emailEvent.to}</td>
    </tr>
  )
}

export default EmailHistoryEvent;
