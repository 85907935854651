import InvoiceAttachmentData from "types/firestore_schema/InvoiceAttachmentData";
import InvoiceAttachmentFile from "types/InvoiceAttachmentFile";

class InvoiceAttachmentMapper {

  static toInvoiceAttachmentDatas(invoiceAttachmentFiles: InvoiceAttachmentFile[]) {
    return invoiceAttachmentFiles.map(InvoiceAttachmentMapper.toInvoiceAttachmentData);
  }

  static toInvoiceAttachmentFiles(invoiceAttachmentDatas: InvoiceAttachmentData[]) {
    return invoiceAttachmentDatas.map(InvoiceAttachmentMapper.toInvoiceAttachmentFile);
  }

  private static toInvoiceAttachmentData(invoiceAttachmentFile: InvoiceAttachmentFile) {
    const invoiceAttachmentData: InvoiceAttachmentData = {
      fileName: invoiceAttachmentFile.fileName,
      url: invoiceAttachmentFile.url!
    };
    return invoiceAttachmentData;
  }
  
  private static toInvoiceAttachmentFile(invoiceAttachmentData: InvoiceAttachmentData) {
    const invoiceAttachmentFile: InvoiceAttachmentFile = {
      id: invoiceAttachmentData.url,
      uploading: false,
      fileName: invoiceAttachmentData.fileName,
      url: invoiceAttachmentData.url
    };
    return invoiceAttachmentFile;
  }

}

export default InvoiceAttachmentMapper;
