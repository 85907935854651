import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import VatCategory from 'types/VatCategory';
import Localizer from 'utils/Localizer';

class InvoicePdfHelper {

  private styles: any;

  constructor(styles: any) {
    this.styles = styles;
  }

  getVatCategories(vatCategories: VatCategory[]) {
    const rows = vatCategories.map(vatCategory => {
      return (
        <View style={[this.styles.flexRow, this.styles.vatCategoryRow]} key={vatCategory.taxAmount}>
          <Text style={this.styles.rowEntry}>{Localizer.getNumberString(vatCategory.rate, 2)}%</Text>
          <Text style={this.styles.rowEntry}>{Localizer.getNumberString(vatCategory.taxableAmount, 2)} EUR</Text>
          <Text style={this.styles.rowEntry}>{Localizer.getNumberString(vatCategory.taxAmount, 2)} EUR</Text>
        </View>
      )
    });
    return (
      <View style={this.styles.vatCategories}>
        <View style={[this.styles.flexRow, this.styles.vatCategoryRow, this.styles.vatCategoryHeader]}>
          <Text style={this.styles.rowEntry}>Stopnja DDV</Text>
          <Text style={this.styles.rowEntry}>Osnova za DDV</Text>
          <Text style={this.styles.rowEntry}>Znesek DDV</Text>
        </View>
        {rows}
      </View>
    )
  }

  parseHtml(htmlString: string) {
    const document = new DOMParser().parseFromString(htmlString, "text/html");
    const body = document.querySelector('body');
    if (!body) {
      return htmlString;
    }

    const nodes = Array.from(body.childNodes);
    return nodes.map(node => {
      switch (node.nodeName) {
        case 'SIZE12':
        case 'SIZE11':
        case 'SIZE10':
        case 'SIZE9':
        case 'SIZE8':
        case 'SIZE7':
          const fontStyle = this.getFontStyle(node.nodeName);
          return (<Text key={node.textContent} style={fontStyle}>{node.textContent}</Text>);
        default:
          return (<Text key={node.textContent}>{node.textContent}</Text>);
      }
    });
  }

  private getFontStyle(nodeName: string) {
    switch (nodeName) {
      case 'SIZE12':
        return this.styles.annotations.size12;
      case 'SIZE11':
        return this.styles.annotations.size11;
      case 'SIZE10':
        return this.styles.annotations.size10;
      case 'SIZE9':
        return this.styles.annotations.size9;
      case 'SIZE8':
        return this.styles.annotations.size8;
      case 'SIZE7':
        return this.styles.annotations.size7;
      default:
        return '';
    }
  }

}

export default InvoicePdfHelper;
