import InvoiceData from "types/InvoiceData";
import InvoiceItemListSummary from "types/InvoiceItemListSummary";
import IbanHelper from "utils/IbanHelper";
import PaymentReferenceProvider from "utils/PaymentReferenceProvider";

const useInvoiceQrCode = (invoiceData: InvoiceData, invoiceNumberText: string, summary: InvoiceItemListSummary) => {

  const getQrCodeText = () => {
    const price = getPriceText();
    const paymentTitle = getPaymentTitle();
    const paymentReference = getPaymentReference();
    const bankAccount = getBankAccount();
    const body = `UPNQR







${price}



${paymentTitle}

${bankAccount}
${paymentReference}
${invoiceData.organization.name.substring(0, 33)}
${invoiceData.organization.address.substring(0, 33)}
${invoiceData.organization.postOffice} ${invoiceData.organization.city.substring(0, 33 - 5)}`;
    return `${body}
    ${body.length}`
  };

  const getPriceText = () => {
    const priceMultiply = Math.floor(summary.priceSum * 100).toString();
    return padLeft(priceMultiply, 11 - priceMultiply.length, "0");
  };
  
  const getPaymentTitle = () => {
    return `Plačilo računa ${invoiceNumberText}`;
  };

  const getPaymentReference = () => {
    const paymentReference = PaymentReferenceProvider.getPaymentReference(invoiceData.invoiceNumber, invoiceData.invoiceNumberYear);
    return paymentReference.replace(/\s/g, "");
  };

  const getBankAccount = () => {
    const bankAccount = IbanHelper.tryFormat(invoiceData.organization.bankAccount); // If the IBAN is in the old form like 10100-0056006340, then this method will convert it to IBAN format, like SI56 1010 0005 6006 340. If it's not in the old format, it will leave it as it is.
    return bankAccount.replace(/\s/g, "");
  };

  const padLeft = (value: string, padding: number, paddingCharacter: string) => {
    return Array(padding + 1).join(paddingCharacter) + value;
  }

  return {
    getQrCodeText
  };

};

export default useInvoiceQrCode;
