const Router = {
  route: (path, pathParameters, queryParameters) => {
    if (path === '/' && !pathParameters && !queryParameters) {
      return path;
    }
    
    let url = path;

    if (pathParameters) {
      const pathParameterNames = Object.keys(pathParameters);
      pathParameterNames.forEach((parameterName) => {
        const searchValue = '{' + parameterName + '}';
        const parameterValue = pathParameters[parameterName];
        url = url.replace(searchValue, parameterValue);
      });
    }

    url = PathParameter.removeUnused(url);
    url = Separator.trim(url);

    if (queryParameters) {
      const queryParameterNames = Object.keys(queryParameters);
      queryParameterNames.forEach((parameterName) => {
        const parameterValue = queryParameters[parameterName];
        url = QueryParameter.append(url, parameterName, parameterValue);
      });      
    }
    
    return url;
  }
};

const PathParameter = {
  // Removes unset path parameters from the given URL.
  removeUnused: (url) => {
    return url.replace(new RegExp('{.+}'), '');
  }
};

const QueryParameter = {
  // Appends query parameter to the given URL.
  append: (url, parameterName, parameterValue) => {
    let separator = '?';
    if (url.includes('?')) {
      separator = '&';
    }

    return url = url + separator + parameterName + '=' + encodeURIComponent(parameterValue);
  }
};

const Separator = {
  // Trims remaining path and query separators from the given URL.
  trim: (url) => {
    if (url.endsWith('/')) {
      return url.substr(0, url.length - 1);
    }

    return url;
  }
};

export default Router;
