import React from 'react';

const Header = ({ children }: any) => {
  return (
    <div className="row">{children}</div>
  );
};

const PackageHeader = () => {
  return (
    <div className="column row-header hide-on-mobile">
      <div className="column-header"></div>
      <Header>Število računov</Header>
      <Header>PDF izvoz računa</Header>
      <Header>e-RAČUNI (e-SLOG 2.0.)</Header>
      <Header>Predračuni</Header>
      <Header>Avansni računi</Header>
      <Header>Avtomatski vnos partnerjev iz AJPES baze</Header>
      <Header>Pošiljanje računov po e-pošti</Header>
      <Header>Možnost pošiljanja kopij računov v računovodstvo</Header>
      <Header>Tisk računa neposredno iz aplikacije</Header>
      <Header>Statistika izdanih, plačanih in neplačanih računov</Header>
      <Header>Izdajanje računov v več valutah</Header>
      <Header>Iskanje računov po partnerju in številki računa</Header>
      <Header>Neomejeno število partnerjev</Header>
      <Header>Poljubno številčenje računov</Header>
      <Header>Varna SSL povezava</Header>
      <Header>Pomoč preko e-pošte</Header>
      <Header>Pomoč preko telefona</Header>
      <Header>Odstrani Evelope logotip iz računov</Header>
    </div>
  );
};

export default PackageHeader;
