import React from 'react';

const OrganizationOption = (props) => {
  const {
    innerProps,
    isDisabled,
    getStyles,
    data} = props;
  const organization = data.organization;

  if (isDisabled) {
    return null;
  }

  if (!organization) {
    return (
      <div
      className="organization-option"
      {...innerProps}
      ref={props.innerRef}
      style={getStyles('option', props)}>
        <div className="name normal">{data.label}</div>
        <div className="address small">{data.addLabel}</div>
      </div>
    );
  }

  return (
    <div
    className="organization-option"
    {...innerProps}
    ref={props.innerRef}
    style={getStyles('option', props)}>
      <div className="name normal">{data.detailedLabel}</div>
      <div className="address small">{organization.address}</div>
      <div className="city small">{organization.postOffice} {organization.city}</div>
      <div className="country small">{organization.country}</div>
    </div>
    );
};

export default OrganizationOption;
