import { useFirebase } from 'config/Firebase';
import SubscriptionsProvider from 'providers/SubscriptionsProvider';
import { useEffect, useState } from 'react';
import SubscriptionData from 'types/SubscriptionData';

const useActiveSubscriptionInfo = (organizationId: string) => {
  
  const [activeSubscriptions, setActiveSubscriptions] = useState<SubscriptionData[] | undefined>();
  const firebaseContext = useFirebase();
  const db = firebaseContext!.firebase.db();
  
  useEffect(() => {
    const getActiveSubscription = async () => {
      const provider = new SubscriptionsProvider(db, organizationId);
      const activeSubscriptions = await provider.getActiveSubscriptions();
      setActiveSubscriptions(activeSubscriptions);
    };

    getActiveSubscription();
  }, [db, organizationId]);
  
  return {
    activeSubscriptions
  };

};

export default useActiveSubscriptionInfo;
