import InvoiceNumberComponents from '../types/InvoiceNumberComponents';

class InvoiceNumberComponentsParser {

  static parse(text: string) : InvoiceNumberComponents | null {
    if (text.indexOf('/') > 0) {
      const invoiceNumberComponents = text.split('/');
      const firstPart = invoiceNumberComponents[0].trim();
      const invoiceNumberAndPrefix = firstPart.split(/(\d+)/).filter(x => x !== '');
      if (invoiceNumberAndPrefix.length === 0 || invoiceNumberAndPrefix.length > 2) {
        return null;
      }

      const prefixText = invoiceNumberAndPrefix.length === 2 ? invoiceNumberAndPrefix[0] : '';
      const invoiceNumber = parseInt(invoiceNumberAndPrefix.length === 2 ? invoiceNumberAndPrefix[1].trim() : invoiceNumberAndPrefix[0].trim());
      const year = parseInt(invoiceNumberComponents[1].trim());
      if (!isNaN(invoiceNumber) && !isNaN(year) && year > 999 && year < 10000) {
        return {
          prefixText: prefixText,
          number: invoiceNumber,
          year: year
        };
      }
    }

    return null;
  }

}

export default InvoiceNumberComponentsParser;
