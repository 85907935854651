import React from 'react';
import { ReactComponent as DocumentIcon } from 'icons/bills.svg';

type InvoiceAttachmentProps = {
  id: string;
  url?: string | undefined;
  fileName: string;
  uploading?: boolean | undefined;
  uploadProgress?: number | undefined;
  errorMessage?: string | undefined;
  onClick?: (attachmentUrl: string | undefined) => void | undefined;
  onRemoveButtonClicked?: (fileId: string) => void | undefined;
};

const InvoiceAttachment = (props: InvoiceAttachmentProps) => {

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(props.url);

      e.preventDefault();
    }
  }

  const handleRemoveButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props.onRemoveButtonClicked!(props.id);

    e.stopPropagation();
    e.preventDefault();
  }

  const hasRemoveButton = props.onRemoveButtonClicked ? true : false;
  const removeButton = hasRemoveButton ? (
    <button className="remove" onClick={handleRemoveButtonClick}>&#10005;</button>
  ) : null;

  const getClassName = () => {
    let className = 'invoice-attachment';

    if (hasRemoveButton) {
      className += ' with-remove-button';
    }

    if (props.uploading) {
      className += ' uploading';
    }

    if (props.errorMessage) {
      className += ' error';
    }

    return className;
  }

  const className = getClassName();
  const title = props.errorMessage ? props.errorMessage : props.fileName;

  const uploadProgressWidth = props.uploadProgress !== undefined ? props.uploadProgress : 0;
  const uploadProgressIndicatorStyle = {
    width: `${uploadProgressWidth}%`
  };

  return (
    <a href={props.url} download={props.fileName} rel="noopener noreferrer" target="_blank" className={className} onClick={handleClick} title={title}>
      <DocumentIcon />
      <div className="upload-indicator" style={uploadProgressIndicatorStyle}></div>
      {props.fileName}
      {removeButton}
    </a>
  );

}

export default InvoiceAttachment;
