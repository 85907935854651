import React, { useState } from 'react';
import { useFirebase } from 'config/Firebase';
import AlertModal from 'components/modals/AlertModal';
import { useAuth } from 'utils/Auth';
import { ReactComponent as SignOutIcon } from 'icons/sign-out.svg';
import NavigationItem from 'components/NavigationItem.js';

const SignOutButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentUser = useAuth().currentUser;
  const accountEmail = currentUser.email;
  const title = "Ali ste prepričani da se želite odjaviti?";
  const text = "Prijavljeni ste kot " + accountEmail + ".";
  const firebaseContext = useFirebase();

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const confirm = () => {
    closeDialog();
    firebaseContext.firebase.signOut();
  };

  return (
    <>
      <NavigationItem title="Odjava" onClick={openDialog}>
        <SignOutIcon />
      </NavigationItem>
      <AlertModal open={dialogOpen} onCancel={closeDialog} onConfirm={confirm} title={title} text={text} confirmButtonText='Da, odjavi me' />
    </>
  );
};

export default SignOutButton;
