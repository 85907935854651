import InvoiceData from "types/InvoiceData";
import xmlbuilder from "xmlbuilder";
import InvoiceItemListSummary from "types/InvoiceItemListSummary";
import XmlHelper from "./XmlHelper";
import PaymentReferenceProvider from "./PaymentReferenceProvider";
import InvoiceFileNameProvider from "./InvoiceFileNameProvider";

class EnvelopeGenerator {
  
  static generateXml(invoiceData: InvoiceData, summary: InvoiceItemListSummary) {
    const envelopeElement = xmlbuilder.create('envelope')
      // .att('xmlns', '')
      .att('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');

    const senderElement = envelopeElement.ele('sender');
    senderElement.ele('name').txt(XmlHelper.getTextAsString(invoiceData.organization.name, 70));
    
    const senderEddressElement = senderElement.ele('sender_eddress');
    senderEddressElement.ele('sender_agent').txt(XmlHelper.getBankBusinessIdentifierCode(invoiceData.organization.bankBusinessIdentifierCode));
    senderEddressElement.ele('sender_mailbox').txt(XmlHelper.getIban(invoiceData.organization.bankAccount));

    const receiverElement = envelopeElement.ele('receiver');
    receiverElement.ele('name').txt(XmlHelper.getTextAsString(invoiceData.partner.name, 70));

    receiverElement.ele('receiver_identifier').txt(invoiceData.partner.taxNumber);
    
    const receiverEddressElement = receiverElement.ele('receiver_eddress');
    receiverEddressElement.ele('receiver_agent').txt(XmlHelper.getBankBusinessIdentifierCode(invoiceData.partner.bankBusinessIdentifierCode));
    receiverEddressElement.ele('receiver_mailbox').txt(XmlHelper.getIban(invoiceData.partner.bankAccount));

    const invoiceNumberText = '{prefix}{number}{year}'
      .replace("{prefix}", invoiceData.invoiceNumberPrefixText)
      .replace("{number}", invoiceData.invoiceNumber.toFixed(0))
      .replace("{year}", invoiceData.invoiceNumberYear.toFixed(0))
      .replace(/ +/g, '');

    const docDataElement = envelopeElement.ele('doc_data');
    docDataElement.ele('doc_type').txt('0002');
    docDataElement.ele('doc_type_ver').txt('01');
    docDataElement.ele('doc_id').txt(invoiceNumberText);
    docDataElement.ele('external_doc_id').txt(invoiceNumberText);
    docDataElement.ele('timestamp').txt(XmlHelper.dateTimeString(new Date()));

    const paymentDataElement = envelopeElement.ele('payment_data');
    paymentDataElement.ele('payment_method').txt('0');
    
    const creditorElement = paymentDataElement.ele('creditor');
    creditorElement.ele('name').txt(XmlHelper.getTextAsString(invoiceData.organization.name, 70));
    creditorElement.ele('country').txt('SI');
    creditorElement.ele('address').txt(XmlHelper.getTextAsString(invoiceData.organization.address, 70));
    creditorElement.ele('address').txt(XmlHelper.getTextAsString(`${invoiceData.organization.postOffice} ${invoiceData.organization.city}`, 70));
    creditorElement.ele('creditor_agent').txt(XmlHelper.getBankBusinessIdentifierCode(invoiceData.organization.bankBusinessIdentifierCode));
    creditorElement.ele('creditor_account').txt(XmlHelper.getIban(invoiceData.organization.bankAccount));
    
    const debtorElement = paymentDataElement.ele('debtor');
    debtorElement.ele('name').txt(XmlHelper.getTextAsString(invoiceData.partner.name, 70));
    debtorElement.ele('country').txt('SI');
    debtorElement.ele('address').txt(XmlHelper.getTextAsString(invoiceData.partner.address, 70));
    debtorElement.ele('address').txt(XmlHelper.getTextAsString(`${invoiceData.partner.postOffice} ${invoiceData.partner.city}`, 70));
    debtorElement.ele('debtor_agent').txt(XmlHelper.getBankBusinessIdentifierCode(invoiceData.partner.bankBusinessIdentifierCode));
    debtorElement.ele('debtor_account').txt(XmlHelper.getIban(invoiceData.partner.bankAccount));
    
    paymentDataElement.ele('requested_execution_date').txt(XmlHelper.dateString(invoiceData.liquidationDate.toDate()));
    paymentDataElement.ele('amount').txt(XmlHelper.getNumberAsString(summary.priceSum));
    paymentDataElement.ele('currency').txt(invoiceData.currency);
    
    const remittanceInformationElement = paymentDataElement.ele('remittance_information');
    remittanceInformationElement.ele('creditor_structured_reference').txt(PaymentReferenceProvider.getPaymentReference(invoiceData.invoiceNumber, invoiceData.invoiceNumberYear));
    remittanceInformationElement.ele('additional_remittance_information').txt(XmlHelper.getTextAsString(invoiceData.attribution.length > 0 ? invoiceData.attribution : ' ', 140));

    paymentDataElement.ele('purpose').txt('OTHR'); // ISO 20022, external code sets

    const attachmentsElement = envelopeElement.ele('attachments');
    attachmentsElement.ele('hash').txt('0000000000000000000000000000000000000000'); // todo hash of .tar.gz file of all attachmennts
    attachmentsElement.ele('size').txt('0'); // todo size in bytes of .tar.gz file of all attachments
    attachmentsElement.ele('count').txt('2');

    // XML attachment
    const xmlAttachmentElement = attachmentsElement.ele('attachment');
    xmlAttachmentElement.ele('filename').txt(InvoiceFileNameProvider.getFileName(invoiceData.invoiceTypeId!, invoiceData.invoiceNumber, invoiceData.invoiceNumberYear, 'xml'));
    xmlAttachmentElement.ele('size').txt('0'); // todo setme size in bytes
    xmlAttachmentElement.ele('type').txt('xml');

    // PDF attachment
    const pdfAttachmentElement = attachmentsElement.ele('attachment');
    pdfAttachmentElement.ele('filename').txt(InvoiceFileNameProvider.getFileName(invoiceData.invoiceTypeId!, invoiceData.invoiceNumber, invoiceData.invoiceNumberYear, 'pdf'));
    pdfAttachmentElement.ele('size').txt('0'); // todo setme size in bytes
    pdfAttachmentElement.ele('type').txt('pdf');

    return envelopeElement.toString({ pretty: true });
  }

}

export default EnvelopeGenerator;
