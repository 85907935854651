import React, { useEffect } from 'react';
import { ReactComponent as ReceiptIcon } from 'icons/receipt.svg';
import { ReactComponent as ReceiptPaidIcon } from 'icons/receipt-paid.svg';
import { ReactComponent as ReceiptUnpaidIcon } from 'icons/receipt-unpaid.svg';
import InvoiceStatsBox from './InvoiceStatsBox';
import { useFirebase } from 'config/Firebase';
import useInvoiceStats, { Currency } from 'hooks/useInvoiceStats';
import InvoiceStatYearChanger from './InvoiceStatYearChanger';

type InvoiceStatsProps = {

  userId: string;

  organizationId: string;

  visible: boolean;

}

const InvoiceStats = ({ userId, organizationId, visible }: InvoiceStatsProps) => {
  
  const [state, dispatch] = useInvoiceStats();

  const firebaseContext = useFirebase();
  
  useEffect(() => {
    const db = firebaseContext!.firebase.db();
    const summaryRef = db
      .collection('organizations')
      .doc(organizationId)
      .collection('invoiceStatsPerYear')
      .doc(state.selectedYear.toString())
      .collection('invoiceStatsPerCurrency')
      .where('authorUid', '==', userId);
    const unsubscribeFromUpdates = summaryRef.onSnapshot((snapshot) => {
      snapshot.forEach(document => {
        const data = document.data();
        dispatch({
          type: 'update',
          currency: {
            id: document.id,
            paid: data.paid,
            notPaid: data.notPaid
          }
        });
      });

      // If there is no invoices for the selected year, show zero numbers.
      if (snapshot.size === 0) {
        dispatch({
          type: 'reset'
        });
      }
    }); 
    
    return () => {
      unsubscribeFromUpdates();
    };
  }, [firebaseContext, organizationId, userId, state.selectedYear, dispatch]);

  const selectNextCurrency = () => {
    let currentIndex = -1;

    state.currencies.forEach(currency => {
      if (currency.id === state.selectedCurrency) {
        currentIndex = state.currencies.indexOf(currency);
      }
    })

    let nextIndex = currentIndex + 1;
    if (nextIndex >= state.currencies.length) {
      nextIndex = 0;
    }
    const nextCurrency = state.currencies[nextIndex];
    if (currentIndex !== nextIndex) {
      dispatch({ type: 'selectCurrency', id: nextCurrency.id });
    }
  };

  const handleYearChanged = (newSelectedYear: number, oldSelectedYear: number) => {
    dispatch({ type: 'selectYear', year: newSelectedYear });
  };
  
  const getClassName = () => {
    let className = 'invoice-stats-bar hide-on-mobile';
    
    if (!visible) {
      className += ' hidden';
    }

    return className;
  }

  const getCurrency = () : Currency => {
    let found: Currency | undefined;

    state.currencies.forEach(currency => {
      if (currency.id === state.selectedCurrency) {
        found = currency;
      }
    });

    return found!;
  }
  
  const clickable = state.currencies.length > 1;
  const className = getClassName();
  const currency = getCurrency();
  const yearChanger = <InvoiceStatYearChanger selectedYear={state.selectedYear} onSelectedYearChanged={handleYearChanged} />;
  return (
    <div className={className}>
      <InvoiceStatsBox title={`Izdani računi v ${state.selectedYear}`} subtitle={yearChanger} sum={currency.paid + currency.notPaid} currency={state.selectedCurrency!} onCurrencyClicked={selectNextCurrency} clickable={clickable} loading={state.loading}>
        <ReceiptIcon />
      </InvoiceStatsBox>
      <InvoiceStatsBox title={`Plačani v ${state.selectedYear}`} subtitle={yearChanger} sum={currency.paid} currency={state.selectedCurrency!} onCurrencyClicked={selectNextCurrency} clickable={clickable} loading={state.loading}>
        <ReceiptPaidIcon />
      </InvoiceStatsBox>
      <InvoiceStatsBox title={`Neplačani v ${state.selectedYear}`} subtitle={yearChanger} sum={currency.notPaid} currency={state.selectedCurrency!} onCurrencyClicked={selectNextCurrency} clickable={clickable} loading={state.loading}>
        <ReceiptUnpaidIcon />
      </InvoiceStatsBox>
    </div>
  )
}

export default InvoiceStats;
