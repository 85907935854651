import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from 'utils/Auth';
import useValidation from 'hooks/useValidation.js';
import useCollection from 'hooks/useCollection.js';
import LiveSelectBox from 'components/forms/LiveSelectBox.js';

const Settings = () => {

  const { register, errors, control, handleSubmit } = useForm();
  const validationErrors = useValidation(errors);

  const { userData, currentUser } = useAuth();
  const usersCollection = useCollection('users');
  
  const saveUserData = useCallback((formData) => {
    const data = userData;
    data.organizationId = formData.organizationId;
    data.organizationName = formData.organizationName;    
    usersCollection.addOrUpdate(currentUser.uid, data);
  }, [usersCollection, currentUser.uid, userData]);

  if (!userData) {
    return (
      <></>
    );
  }

  return (
    <form className="page settings" onSubmit={handleSubmit(saveUserData)}>
      <div className="dummy-top"></div>
      <h1>Nastavitve</h1>
      <div className="panel">
        <LiveSelectBox name="organization"
        collectionName="organizations"
        field="name"
        searchable={false}
        defaultValue={userData ? userData.organizationId : null}
        defaultText={userData ? userData.organizationName : null}
        register={register}
        control={control} />
        <div className="button-bottom-bar error">
          {validationErrors}
        </div>
        <div className="button-bottom-bar r-align">
          <button className="buttonish text-only" type="submit">Shrani</button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(Settings);
