import React from 'react';

type InvoiceNumberTextBoxProps = {
  state: InvoiceTypeState;
  onChange: (newState: InvoiceTypeState) => void;
}

type InvoiceTypeState = {
  label: string;
  id: string;
  prefixText: string;
  defaultNextInvoiceNumberText: string;
  nextInvoiceNumberText: string;
  year: number;
  visible: boolean;
}

const InvoiceNumberTextBox = (props: InvoiceNumberTextBoxProps) => {

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...props.state
    };

    newState.nextInvoiceNumberText = event.target.value;

    props.onChange(newState);
  };

  const handleNumberBlur = (event: React.ChangeEvent<HTMLInputElement>) => {    
    const newState = {
      ...props.state
    };
    
    const isNextInvoiceNumberSet = event.target.value.trim().length > 0;
    if (!isNextInvoiceNumberSet) {
      newState.nextInvoiceNumberText = newState.defaultNextInvoiceNumberText;
      props.onChange(newState);
    }
  };
  
  const handlePrefixTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...props.state
    };

    newState.prefixText = event.target.value;

    props.onChange(newState);
  };

  const yearSuffix = (
    <span className="control-info">
      / {props.state.year}
    </span>
  );

  return (    
    <label className={`text-box control-row full-line full ${props.state.visible ? '' : 'hidden'}`}>
      <span className="text">{props.state.label}</span>
      <div className="input-holder">
        <div className="flex-wrapper">
          <input className="inputish invoice-number-prefix-text" value={props.state.prefixText} onChange={handlePrefixTextChange} />
          <input type="number" className="inputish" value={props.state.nextInvoiceNumberText} onChange={handleNumberChange} onBlur={handleNumberBlur} />
          {yearSuffix}
        </div>
      </div>
    </label>
  );

};


export default InvoiceNumberTextBox;

export type { InvoiceTypeState };
