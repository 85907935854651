import Router from 'utils/Router';
import * as Routes from 'constants/Routes.js';
import OrganizationSubscriptionSource from "types/OrganizationSubscriptionSource";
import { AxiosInstance } from 'axios';

class PortalSessionClient {
  
  private organizationId: string;

  private axiosInstance: AxiosInstance;

  constructor(organizationId: string, axiosInstance: AxiosInstance) {
    this.organizationId = organizationId;
    this.axiosInstance = axiosInstance;
  }

  createPortalSession = async (customerId: string) => {
    const returnUrl = process.env.REACT_APP_ENDPOINT_URL + Router.route(Routes.organizationSubscriptions, { organizationId: this.organizationId }, { source: OrganizationSubscriptionSource.CheckoutEdit });
    const body = {
      customerId: customerId,
      returnUrl: returnUrl
    };
    const result = await this.axiosInstance.post('/create-portal-session', body);
    console.log(`Create portal session successful, got data: ${result.data}`);
    return result.data.url;
  }

}

export default PortalSessionClient;
