enum EmailEventType {
  Bounce,
  Delivered,
  Spam,
  Requests,
  Opened,
  FirstOpened,
  Clicks,
  Invalid,
  Deferred,
  Blocked,
  Unsubscribed,
  Error,
  LoadedByProxy,
  Unknown
}

export default EmailEventType;
