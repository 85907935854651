class OrganizationSubscriptionSource {

  static PaymentUpdate = 'payment-update';
  
  static LimitReached = 'limit-reached';
  
  static CreateButton = 'create-button';

  static Settings = 'settings';

  static CheckoutSuccess = 'checkout-success';

  static CheckoutCancel = 'checkout-cancel';

  static CheckoutEdit = 'checkout-edit';

}

export default OrganizationSubscriptionSource;
