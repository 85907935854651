import React from 'react';
import CheckoutSessionClient from 'utils/CheckoutSessionClient';
import Bugsnag from '@bugsnag/js';
import PortalSessionClient from 'utils/PortalSessionClient';
import { useFirebase } from 'config/Firebase';
import { useAuth } from 'utils/Auth';
import CheckmarkButtonEmoji from 'emojis/check-mark-button_2705.png';
import CrossmarkEmoji from 'emojis/cross-mark_274c.png'
import SmilingFaceWithSunglassesEmoji from 'emojis/smiling-face-with-sunglasses_1f60e.png'

type PackageProps = {
  name: string;
  description: string;
  invoicesPerMonthText: string;
  invoicesPerMonthTextForMobile: string;
  isActive: boolean;
  customerId?: string | undefined;
  canBuy: boolean;
  priceId?: string | undefined;
  priceText: string;
  organizationId: string;
  hasWatermark: boolean;
  hasPhoneSupport: boolean;
  isMostPopular: boolean;
};

const Yes = () => {
  return (
    <div className="row"><img className="emoji top5" src={CheckmarkButtonEmoji} alt="check-mark" /></div>
  );
};

const No = () => {
  return (
    <div className="row"><img className="emoji top5" src={CrossmarkEmoji} alt="cross-mark" /></div>
  );
};

const Package = (props: PackageProps) => {
  
  const firebaseContext = useFirebase();
  const { createBackendRequest } = useAuth();

  const getFooterContent = () => {
    if (props.isActive) {
      const selectedHtml = (
        <div>
          <img className="emoji top5" src={SmilingFaceWithSunglassesEmoji} alt="smiling-face-with-sunglasses" /> Trenutno izbran
        </div>
      );

      if (props.customerId) {
        return (
          <div className="selected">
            {selectedHtml}
            <button className="buttonish" onClick={() => editSubscription(props.customerId!)}>Uredi</button>
          </div>
        );
      }

      return (
        <div className="selected">
          {selectedHtml}
        </div>
      );
    };

    if (props.priceId && props.canBuy) {
      return (
        <button className="buttonish" onClick={() => createCheckoutSession(props.priceId!)}>Kupi</button>
      );
    }

    return undefined;
  };
  
  const createCheckoutSession = async (priceId: string) => {
    try {
      firebaseContext!.firebase.logEvent('create_checkout_session');

      const axiosInstance = createBackendRequest();
      const checkoutSessionClient = new CheckoutSessionClient(props.organizationId, axiosInstance);
      const url = await checkoutSessionClient.createCheckoutSession(priceId);
      window.location.href = url;
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  };
  
  const editSubscription = async (customerId: string) => {
    try {
      firebaseContext!.firebase.logEvent('edit_subscription');

      const axiosInstance = createBackendRequest();
      const portalSessionClient = new PortalSessionClient(props.organizationId, axiosInstance);
      const url = await portalSessionClient.createPortalSession(customerId);
      window.location.href = url;
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  };
  
  const footerContent = getFooterContent();
  const phoneSupport = props.hasPhoneSupport ? <Yes /> : <No />
  const watermark = !props.hasWatermark ? <Yes /> : <No />
  const mostPopularRibbon = props.isMostPopular ? <div className="ribbon ribbon-top-right"><div><div className="text">Top izbira</div></div></div> : null;
  const activeRibbon = props.isActive && props.customerId ? <div className="ribbon green ribbon-top-right"><div><div className="text">Aktiven</div></div></div> : null;

  const getClassName = () => {
    let className = "box";

    if (props.isMostPopular) {
      className += " most-popular";
    }

    return className;
  }

  return (
    <div className={getClassName()}>
      <div className="column-header ribbon-holder">
        {mostPopularRibbon}
        {activeRibbon}
        <h4>
          {props.name}
        </h4>
        <h1>
          {props.priceText}
        </h1>
        <div className="description">
          <div className="hide-on-mobile">
            {props.description}
          </div>
          <div className="show-only-on-mobile">
            {props.invoicesPerMonthTextForMobile}
          </div>
        </div>
      </div>

      <div className="row">{props.invoicesPerMonthText}</div>
      <Yes />
      <Yes />
      <Yes />
      <Yes />      
      <Yes />
      <Yes />
      <Yes />
      <Yes />      
      <Yes />
      <Yes />
      <Yes />
      <Yes />
      <Yes />      
      <Yes />
      <Yes />
      {phoneSupport}
      {watermark}

      <div className="column-footer">
        {footerContent}              
      </div>
    </div>
  );

};

export default Package;
