import React from 'react';
import TextBox from 'components/forms/TextBox.js';
import CheckBox from 'components/forms/CheckBox.js';

const OrganizationForm = ({ data, hasEmail, detailsRequired, hasTaxDebtor, hasGlnCode, autoFocus, errors, register }) => {
  if (!data) {
    data = {};
  }

  const taxDebtorHtml = hasTaxDebtor ? (
    <CheckBox name="taxdebtor" fullLine label="Davčni zavezanec" defaultChecked={data.taxDebtor} spacing ref={register} />
  ) : null;

  const bankAccountHtml = true ? (
    <TextBox name="bankaccount" fullLine validationFailed={errors.bankaccount} type="text" label="IBAN" defaultValue={data.bankAccount} ref={register({ required: detailsRequired ? 'IBAN mora biti vpisan.' : undefined })} />
  ) : null;

  const bankBusinessIdentifierCodeHtml = true ? (
    <TextBox name="bankBusinessIdentifierCode" fullLine validationFailed={errors.bankBusinessIdentifierCode} type="text" label="BIC koda (swift)" defaultValue={data.bankBusinessIdentifierCode} ref={register} />
  ) : null;

  const emailHtml = hasEmail ? (
    <TextBox name="email" fullLine validationFailed={errors.email} type="text" label="E-mail" defaultValue={data.email} ref={register} />
  ) : null;

  const glnHtml = hasGlnCode ? (
    <TextBox name="glnCode" fullLine validationFailed={errors.glnCode} type="text" label="GLN koda" defaultValue={data.glnCode} ref={register} />
  ) : null;

  const dummyHtml = !hasGlnCode ? (
    <div className="control-row full-line"></div>
  ) : null;

  return (
    <>
      <TextBox name="name" autoFocus={autoFocus} className="full" fullLine validationFailed={errors.name} type="text" label="Ime organizacije" defaultValue={data.name} ref={register({ required: 'Ime organizacije mora biti vpisano.' })} />
      <TextBox name="address" fullLine validationFailed={errors.address} type="text" label="Naslov" defaultValue={data.address} ref={register({ required: detailsRequired ? 'Naslov mora biti vpisan.' : false })} />
      <TextBox name="zip" fullLine validationFailed={errors.zip} type="text" label="Poštna številka" defaultValue={data.postOffice} ref={register({ required: detailsRequired ? 'Poštna številka mora biti vpisana.' : false })} />
      <TextBox name="city" fullLine validationFailed={errors.city} type="text" label="Mesto" defaultValue={data.city} ref={register({ required: detailsRequired ? 'Mesto mora biti vpisano.' : false })} />
      <TextBox name="country" fullLine validationFailed={errors.country} type="text" label="Država" defaultValue={data.country} ref={register} />
      <TextBox name="taxnumber" fullLine validationFailed={errors.taxnumber} type="text" label="Davčna številka" defaultValue={data.taxNumber} ref={register({ required: detailsRequired ? 'Davčna številka mora biti vpisana.' : false })} />
      {taxDebtorHtml}
      <TextBox name="countryissuedid" fullLine validationFailed={errors.countryissuedid} type="text" label="Matična številka" defaultValue={data.countryIssuedId} ref={register} />
      {dummyHtml}
      {bankAccountHtml}
      {bankBusinessIdentifierCodeHtml}
      {glnHtml}
      {emailHtml}
    </>
  );
};

export default OrganizationForm;
