const getSelectStyle = (error) => {
  return {
    option: (provided, state) => ({
      ...provided,
      lineHeight: 1.5,
      color: '#242526',
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      border: getControlBorder(error, state),
      background: getControlBackground(provided, error),
      borderRadius: 6,
      height: 40,
      cursor: 'text'
    }),
    multiValue: (provided, { data }) => ({
      ...provided,
      cursor: 'default'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: data.color
      },
    }),
  };
};

const getControlBorder = (error, state) => {
  if (error) {
    return '1px solid #b53310';
  }

  if (state.isFocused) {
    return '1px solid #2684FF !important';
  }

  return '1px solid #d0d7e6';
};

const getControlBackground = (provided, error) => {
  if (error) {
    return '#ffe8e2';
  }

  return provided.background;
};

export default getSelectStyle;
