import InvoiceNumberPrefixText from "types/InvoiceNumberPrefixText";

class InvoiceNumberPrefixTextProvider {

  static getPrefixText(invoiceNumberPrefixTexts: InvoiceNumberPrefixText[], invoiceTypeId: string) {
    const filteredList = invoiceNumberPrefixTexts.filter(x => x.invoiceTypeId === invoiceTypeId);
    if (filteredList.length !== 1) {
      throw new Error(`Found ${filteredList.length} prefix texts for invoice type ${invoiceTypeId}`);
    }

    return filteredList[0].prefixText;
  }
    
}

export default InvoiceNumberPrefixTextProvider;
