import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import TextBox from '../forms/TextBox';
import { useForm } from 'react-hook-form';

type YearSelectionModalProps = {
  year: number;
  open: boolean;
  onSave: (year: number) => void;
  onClose: () => void;
}

const YearSelectionModal = (props: YearSelectionModalProps) => {

  const [yearText, setYearText] = useState(props.year.toString());
  const { handleSubmit } = useForm();

  const close = () => {
    reset();

    props.onClose();
  };
  
  const save = () => {
    const year = parseInt(yearText);
    if (!isNaN(year)) {
      props.onSave(year);
    } else {
      props.onClose();
    }      
  };

  const reset = () => {
    setYearText(props.year.toString());
  };

  const handleYearTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearText(event.target.value);
  };

  return (
    <Dialog open={props.open} onClose={close} className="modal-content">      
      <form onSubmit={handleSubmit(save)}>
        <div className="close-button" onClick={close}>
          <CloseIcon />
        </div>
        <h1 className="title">Izberi leto</h1>
        <div className="split-table">
          <TextBox 
            type="number"
            label="Leto"
            value={yearText}
            placeholder="XXXX"
            autoFocus={true}
            className={undefined}
            height={undefined}
            validationFailed={undefined}
            fullLine={true}
            multiLine={undefined}
            info={undefined}
            visible={undefined}
            onChange={handleYearTextChange} />
        </div>
        <div className="button-bottom-bar r-align">
          <button type="submit" className="buttonish text-only">Izberi</button>
        </div>
      </form>
    </Dialog>
  );
};

export default YearSelectionModal;
