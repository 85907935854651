import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'constants/Routes.js';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const AuthenticationLogo = () => {
  return (
    <div className="logo">
      <Link className="logo" to={Routes.login}>
        <LogoIcon />
      </Link>
    </div>
  );
};

export default AuthenticationLogo;
