import Firebase from "config/Firebase";
import 'firebase/compat/storage';
import FileUploader from "./FileUploader";

/// Takes care of uploading invoice attachment files to the Firebase Cloud Storage.
class InvoiceAttachmentFileUploader {

  private static readonly maxFileSize = 10.0 * 1024 * 1024; // 10.0 MB
  
  private readonly fileUploader: FileUploader;
  
  constructor(firebase: Firebase, onUploadProgress: (file: File, progress: number) => void) {
    this.fileUploader = new FileUploader(firebase, InvoiceAttachmentFileUploader.maxFileSize, onUploadProgress);
  }

  async uploadFile(file: File, organizationId: string) {
    const path = `organizations/${organizationId}/attachments`;
    return this.fileUploader.uploadFile(file, path);
  }

  isFileTooLarge(file: File) {
    return this.fileUploader.isFileTooLarge(file);
  }

}

export default InvoiceAttachmentFileUploader;
