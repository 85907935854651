import React, { useState } from 'react';
import { ReactComponent as DropdownArrow } from 'icons/dropdown-arrow.svg';
import YearSelectionModal from './modals/YearSelectionModal';

type InvoiceStatsBoxProps = {

  selectedYear: number;

  onSelectedYearChanged: (newSelectedYear: number, oldSelectedYear: number) => void;

}

const InvoiceStatYearChanger = (props: InvoiceStatsBoxProps) => {

  const [yearSelectionModalOpened, setYearSelectionModalOpen] = useState(false);

  const handleSelectedYearChanged = (newYear: number) => {
    if (props.onSelectedYearChanged) {
      props.onSelectedYearChanged(newYear, props.selectedYear);
    }
    
    closeYearSelectionModal();
  };

  const openYearSelectionModal = () => {
    setYearSelectionModalOpen(true);
  };

  const closeYearSelectionModal = () => {
    setYearSelectionModalOpen(false);
  };

  return (
    <>
      <YearSelectionModal year={props.selectedYear} open={yearSelectionModalOpened} onSave={handleSelectedYearChanged} onClose={closeYearSelectionModal} />
      <div className="year-changer" onClick={openYearSelectionModal}>
        <DropdownArrow width="16" height="16" />
      </div>
    </>
  );

};

export default InvoiceStatYearChanger;
