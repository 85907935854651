import React, { useState } from 'react';
import TextBox from 'components/forms/TextBox.js';
import DefaultInvoiceLiquidationDate from 'components/forms/DefaultInvoiceLiquidationDate.js';
import SignatureBox from 'components/forms/SignatureBox';
import CheckBox from 'components/forms/CheckBox';
import CurrencyBox from './CurrencyBox';

const OrganizationSettings = ({ data, organizationId, errors, register }) => {

  // todo: duplicated code:
  if (!data.email) {
    data.email = '';
  }

  if (!data.phoneNumber) {
    data.phoneNumber = '';
  }

  if (!data.invoiceAttribution) {
    data.invoiceAttribution = 'Znesek poravnajte na bančni račun:\n{IBAN}\n\nOb plačilu navedite sklic: {št. računa}';
  }

  if (!data.currency) {
    data.currency = 'EUR';
  }

  if (!data.invoiceSignature) {
    data.invoiceSignature = data.name;
  }

  if (!data.accountingEmail) {
    data.accountingEmail = '';
  }

  if (!data.liquidationDateDays) {
    data.liquidationDateDays = 30;
  }

  const [currency, setCurrency] = useState(data.currency);

  if (!organizationId) {
    return (
      <>
        <input type="hidden" name="email" defaultValue={data.email} ref={register} />
        <input type="hidden" name="phoneNumber" defaultValue={data.phoneNumber} ref={register} />
        <input type="hidden" name="invoiceAttribution" defaultValue={data.invoiceAttribution} ref={register} />
        <input type="hidden" name="invoiceSignature" defaultValue={data.invoiceSignature} ref={register} />
        <input type="hidden" name="invoiceSignatureFileUrl" defaultValue="" ref={register} />
        <input type="hidden" name="currency" defaultValue={data.currency} ref={register} />
        <input type="hidden" name="liquidationDateDays" defaultValue={data.liquidationDateDays} ref={register} />
        <input type="hidden" name="accountingEmail" defaultValue={data.accountingEmail} ref={register} />
      </>
    );
  }

  return (
    <>
      <div className="split-table">
        <TextBox name="email" type="email" fullLine label="Email" defaultValue={data.email} ref={register} />
        <TextBox name="phoneNumber" type="tel" fullLine label="Telefon" defaultValue={data.phoneNumber} ref={register} />
        <TextBox name="invoiceAttribution" fullLine label="Pripis na računu" multiLine defaultValue={data.invoiceAttribution} ref={register} />
        <SignatureBox textBoxName="invoiceSignature" fileUrlName="invoiceSignatureFileUrl" label="Podpis na računu" defaultTextValue={data.invoiceSignature} defaultFileUrlValue={data.invoiceSignatureFileUrl} organizationId={organizationId} register={register} />
        <CurrencyBox label="Privzeta valuta" value={currency} onChange={setCurrency} fullLine={true} />
        <input name="currency" type="hidden" value={currency} ref={register} />
        <DefaultInvoiceLiquidationDate name="liquidationDateDays" defaultValue={data.liquidationDateDays} register={register} />
        <TextBox name="accountingEmail" validationFailed={errors.accountingEmail} fullLine type="email" label="E-mail računovodstva" defaultValue={data.accountingEmail} ref={register} />
      </div>
      <div className="dummy-top hide-on-mobile"></div>
      <h1>Izgled računa</h1>
      <div className="split-table">
        <CheckBox name="visibleVatBreakdownOnPdf" className="no-top-margin" fullLine label="Prikaži razčlenitev DDV na računu" defaultChecked={data.visibleVatBreakdownOnPdf} ref={register} />
        <CheckBox name="visibleQrCodeOnPdf" className="no-top-margin" fullLine label="Prikaži QR kodo na računu" defaultChecked={data.visibleQrCodeOnPdf} ref={register} />
      </div>
      <div className="dummy-top hide-on-mobile"></div>
    </>
  );
};

export default OrganizationSettings;
