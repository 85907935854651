import React from 'react';

type RemoveButtonProps = {
  onClick: () => void;
}

const RemoveButton = (props: RemoveButtonProps) => {
  return (
    <div className="floaty remove-button" onClick={props.onClick}>&#10005;</div>
  )
}

export default RemoveButton;
