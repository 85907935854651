import React, { useEffect, useState } from 'react';
import { useAuth } from 'utils/Auth';
import { useFirebase } from 'config/Firebase';
import Bugsnag from '@bugsnag/js';

const useEmailVerificationForm = (onEmailVerificationLinkSentSuccess: ((message: string) => void), onEmailVerificationLinkSentError: ((message: string) => void), listenToEmailVerifiedChange: boolean) => {

  const { currentUser } = useAuth();
  const firebaseContext = useFirebase();
  const [emailVerified, setEmailVerified] = useState<boolean>(currentUser.emailVerified);

  const verifyEmail = async () => {
    try {
      const actionCodeSettings: firebase.default.auth.ActionCodeSettings = {
        url: window.location.href
      };
      await currentUser.sendEmailVerification(actionCodeSettings);
      onEmailVerificationLinkSentSuccess(`Povezava poslana na ${currentUser.email}. Preverite vaš e-poštni nabiralnik.`);
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
        onEmailVerificationLinkSentError(error.message);
      } else {
        onEmailVerificationLinkSentError('Prišlo je do napake.');
      }
    }
  };

  useEffect(() => {
    let reloadTimerId: NodeJS.Timeout | null = null;

    const tryCleanTimeout = () => {
      if (reloadTimerId) {
        clearTimeout(reloadTimerId);
        reloadTimerId = null;
      }
    }

    const onIdTokenChangedUnsubscribe = firebaseContext!.firebase.onIdTokenChanged((user) => {  
      if (emailVerified || !listenToEmailVerifiedChange) {
        return;
      }
      
      if (user && user.emailVerified) {
        setEmailVerified(true);
                
        return;
      }

      tryCleanTimeout();
      
      // Calling sendEmailVerification will invoke id token change for the first time. Then, if the email isn't verified yet,
      // this will reload the user after 10 seconds. Then calling getIdToken(true) will again invoke the id token change. This essentially
      // leads to infinite pooling until the user gets his or her email verified.
      reloadTimerId = setTimeout(() => {
        firebaseContext!.firebase.reloadUser();
        firebaseContext!.firebase.getIdToken(true);
      }, 10000);
    });
    

    return () => {
      tryCleanTimeout();
      onIdTokenChangedUnsubscribe();
    }
  }, [firebaseContext, emailVerified, listenToEmailVerifiedChange]);

  const render = () => {
    return !emailVerified ? (
      <React.Fragment>
        <div className="email-verification-required-text">
          Za pošiljanje računov morate najprej potrditi vaš e-mail naslov.<br />
          Potrditev lahko opravite s klikom na povezavo, ki jo boste prejeli na <b>{currentUser.email}</b>.
        </div>
        <div className="button-bottom-bar r-align">
          <button className="buttonish text-only" onClick={verifyEmail}>Pošlji povezavo</button>
        </div>
      </React.Fragment>
    ) : null;
  };

  return {
    emailVerified,
    render
  };

};

export default useEmailVerificationForm;
