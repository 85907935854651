import React from 'react';
import InformationEmoji from 'emojis/information_2139-fe0f.png';

type DisclaimerTextProps = {
  children: any;
};

const DisclaimerText = (props: DisclaimerTextProps) => {
  return (
    <div className="gray-uppercase disclaimer-text">
      <img className="emoji" src={InformationEmoji} alt="information" />
      <span>{props.children}</span>
    </div>
  );
};

export default DisclaimerText;
