import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import OrganizationForm from 'components/forms/OrganizationForm.js';
import useValidation from 'hooks/useValidation.js';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import useCollection from 'hooks/useCollection.js';
import Bugsnag from '@bugsnag/js';

const PartnerEditModal = ({ open, data, partnerId, editCollection, onSave, onClose }) => {

  const partnersCollection = useCollection('partners');
  const { register, errors, handleSubmit } = useForm();
  const validationErrors = useValidation(errors);
  const [editData, setEditData] = useState(null);

  const partnerData = data ? data : editData;

  const savePartner = useCallback(async (formData) => {
    const newData = partnerData ? partnerData : {};
    newData.name = formData.name;
    newData.address = formData.address;
    newData.postOffice = formData.zip;
    newData.city = formData.city;
    newData.country = formData.country;
    newData.taxNumber = formData.taxnumber;
    newData.countryIssuedId = formData.countryissuedid;
    newData.email = formData.email;
    newData.glnCode = formData.glnCode;
    newData.bankAccount = formData.bankaccount;
    newData.bankBusinessIdentifierCode = formData.bankBusinessIdentifierCode;
    
    try {
      if (editCollection) {
        await partnersCollection.addOrUpdate(partnerId, newData);
      }

      onSave(newData);

      if (editCollection) {
        reset();
      }
    }
    catch (error) {
      Bugsnag.notify(error);
      console.log('Error while saving partner: ' + error);
    }
  }, [ partnerData, partnerId, onSave, partnersCollection, editCollection ]);

  const getTitle = () => {
    if (partnerId) {
      return 'Vnesite podatke o partnerju';
    }

    return 'Dodaj partnerja';
  };

  const close = () => {
    reset();
    onClose();
  };

  const reset = () => {
    setEditData(null);
  };

  useEffect(() => {
    const getPartner = async () => {
      const existingData = await partnersCollection.get(partnerId);
      setEditData(existingData);
    };

    if (partnerId && !partnerData) {
      getPartner();
    }
  }, [partnerId, partnerData, partnersCollection]);

  const isFetchingData = !partnerData && partnerId;
  if (isFetchingData) {
    return <></>
  }

  return (
    <Dialog open={open} onClose={close} className="modal-content">
      <div className="close-button" onClick={close}>
        <CloseIcon />
      </div>
      <h1 className="title">{getTitle()}</h1>
      <form onSubmit={handleSubmit(savePartner)}>
        <div className="split-table">
          <OrganizationForm data={partnerData} hasEmail hasGlnCode errors={errors} register={register} autoFocus={!partnerId} />
        </div>
        <div className="button-bottom-bar error">
          {validationErrors}
        </div>
        <div className="button-bottom-bar r-align">
          <button className="buttonish text-only" type="submit">Shrani</button>
        </div>
      </form>
    </Dialog>
  );
};

export default PartnerEditModal;
