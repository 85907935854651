import React from 'react';
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg';

const Pagination = ({ pager }) => {
  const firstButtonHtml = pager.hasPrevious ? (
    <button className="buttonish secondary flip-h" disabled={pager.loading} onClick={pager.getFirst} title="Na prvo stran">
      <ArrowIcon />
    </button>
  ) : null;

  const previousButtonHtml = pager.hasPrevious ? (
    <button className="buttonish secondary" disabled={pager.loading} onClick={pager.getPrevious}>
      Nazaj
    </button>
  ) : null;

  const nextButtonHtml = pager.hasNext ? (
    <button className="buttonish secondary r-float" disabled={pager.loading} onClick={pager.getNext}>
      Naprej
    </button>
  ) : null;

  const lastButtonHtml = pager.hasNext ? (
    <button className="buttonish secondary r-float" disabled={pager.loading} onClick={pager.getLast} title="Na zadnjo stran">
      <ArrowIcon />
    </button>
  ) : null;

  return (
    <div className="pagination">
      {firstButtonHtml}
      {previousButtonHtml}
      {lastButtonHtml}
      {nextButtonHtml}
      <div className="clear-float"></div>
    </div>
  );
};

export default Pagination;
