import React from 'react';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';

const FilterButton = ({ onClick }) => {

  return (
    <button className="buttonish square white create r-float filter-button" title="Filtriraj ..." onClick={onClick}>
      <FilterIcon />
    </button>
  );
};

export default FilterButton;
